import React from "react";

const TextArrayDetailsFieldGroup = ({
  value,
  label,
  arrayType,
  addStyle,
}) => {
  const last_index = value.length - 1;
  let render_value = '-';

  if(value !== "-") {
    if(arrayType === 'array_of_objects') {
      render_value = value.map((obj,idx) => {
        if(idx === last_index) {
          return (
            <span key={`${label}-${idx}`} className='fw-600 mb-0 fs-14 text-capitalize'>{obj.label}</span>
          );
        } else {
          return (
            <span key={`${label}-${idx}`} className='fw-600 mb-0 fs-14 text-capitalize'>{obj.label}, </span>
          );
          // eslint-disable-next-line
        };
      });
    } else if(arrayType === 'array_of_objects_student') {
      render_value = value.map((obj,idx) => {
        if(idx === last_index) {
          return (
            <span key={`${label}-${idx}`} className='fw-600 mb-0 fs-14 text-capitalize'>{obj.language && obj.language.label ? obj.language.label : '-'}</span>
          );
        } else {
          return (
            <span key={`${label}-${idx}`} className='fw-600 mb-0 fs-14 text-capitalize'>{obj.language && obj.language.label ? obj.language.label : '-'}, </span>
          );
          // eslint-disable-next-line
        };
      });
    } else {
      render_value = value.map((text,idx) => {
        if(idx === last_index) {
          return (
            <span key={`${label}-${idx}`} className='fw-600 mb-0 fs-14 text-capitalize'>{text}</span>
          );
        } else {
          return (
            <span key={`${label}-${idx}`} className='fw-600 mb-0 fs-14 text-capitalize'>{text}, </span>
          );
          // eslint-disable-next-line
        };
      });
    };
  };

  return (
    <div className="">
      <label style={{ color: "#959595" }} className="mb-0 fs-14 fw-500">
        {label}
      </label>
      <div
        className="pl-0 mb-2 d-flex align-items-baseline"
        style={{ minHeight: "37px" }}
      >
        <p className="fw-600 mb-0 fs-14" style={addStyle}>
          {render_value}
        </p>
      </div>
    </div>
  );
};

export default TextArrayDetailsFieldGroup;
