import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Line } from 'react-chartjs-2';
import MDSpinner from 'react-md-spinner';

import Notification from '../../common/Notification';

import { connect } from 'react-redux';
import { userGetBookingOverview } from '../../../actions/dashboardActions';

class BookingOverview extends Component {

	constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
			notification: {},
      filter_type: 'weekly',
			data: {},
			totalData: 0
    };
  };

  componentWillMount = () => {
    this.getBookingOverview("weekly");
  };

  getBookingOverview = (type) => {
    this.setState({ isLoading: true, notification: {} });
    this.props.userGetBookingOverview({ type }).then(res => {
      if(res.status === 200) {
        let labels = res.message.data.labels;
        let dataset = res.message.data.dataset;
        
        const data = {
          labels,
          datasets: [
            {
              label: 'Order',
              data: dataset,
              fill: false,
              backgroundColor: 'rgb(243, 123, 79)',
              borderColor: 'rgba(243, 123, 79, 0.2)',
            },
          ],
        };
        
        this.setState({ 
					isLoading: false,
					totalData: res.message.data.totalData,
          data
        });
      } else {
        this.setState({ 
          isLoading: false,
          notification: {
            isError: true,
            text: res.message
          }
        });
      }
    }).catch(err => {
      console.log(err);
      this.setState({ 
        isLoading: false,
				notification: {
					text: "Internal server error. Please try again later.",
					isError: true
				}
      })
    })
  };

  onChangeFilterType = (type) => {
    this.setState({ filter_type: type });
    this.getBookingOverview(type)
  };

  render() {
    const { data, totalData, isLoading, notification, filter_type } = this.state;

    const options = {
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              suggestedMax: 10
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: false,
            },
          },
        ]
      }
		};
		
    return (
      <div className="chart-wrapper">
        <h5 className='text-center mb-20'>Sessions</h5>
        { isLoading ? 
          <div className="h-300px d-flex flex-column align-items-center justify-content-center">
            <MDSpinner/>
            <p className='mt-20 mb-0'>Please wait...</p>
          </div>
					: !isEmpty(notification) ? 
            <div className="h-300px d-flex flex-column align-items-center justify-content-center">
              <Notification notification={notification} />
            </div>
          : <div>
            <div className='d-flex align-items-center justify-content-between mb-30 px-30'>
              <ul className="nav nav-tabs nav-tabs-light-mode bg-transparent mb-0">
                <li className="nav-item">
                  <a className={`nav-link ${filter_type === 'weekly' ? 'active' : ''}`} data-toggle="tab" onClick={() => this.onChangeFilterType('weekly')} href="#weekly-tab">Week</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${filter_type === 'monthly' ? 'active' : ''}`} data-toggle="tab" onClick={() => this.onChangeFilterType('monthly')} href="#monthly-tab">Quarter</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${filter_type === 'yearly' ? 'active' : ''}`} data-toggle="tab" onClick={() => this.onChangeFilterType('yearly')} href="#yearly-tab">Year</a>
                </li>
              </ul>
              <p className="fs-14 mb-0">Total : <strong className="fw-600">{totalData}</strong> {totalData > 1 ? 'sessions' : 'session'}</p>
            </div>
            <Line data={data} options={options} />
          </div>
        }
      </div>
    );
  }
}

export default connect(null, { userGetBookingOverview })(BookingOverview);