import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import LoadingGift from '../../../common/LoadingGift';
import Notification from '../../../common/Notification';
import StudentProfileDetails from './studentProfileDetails';
import StudentAccountDetails from './studentAccountDetails';
import StudentHistoryAppointment from './studentAppointmentHistory';
import StudentPackageOrdersHistory from './studentPackageOrdersHistory';
import StudentEditAccount from './studentEditAccount';
import StudentEditProfile from './studentEditProfile';
import EditCreditModal from './editCreditModal';

import { connect } from 'react-redux';
import { userGetSingleStudent, userDeleteStudent } from '../../../../actions/studentsActions';
import StudentVoucherOrdersHistory from './studentVoucherOrdersHistory';
import StudentCreditHistory from './studentCreditHistory';

class StudentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_id: this.props.history.location.state,
      isLoading: false,
      isLoading_delete: false,
      notification: {},
      onEditProfile: false,
      onEditAccount: false,
      onAddCredit: false,
      active_tab: 'student-appointment-history',
      default_avatar: '/assets/img/icon/male.svg',
      // Data from DB
      avatar_display: "",
      appointments: [],
      avatar_filename: "",
      bank_number: "",
      bridge_language: [],
      created_date: "",
      credit_balance: 0,
      credit_expired: "",
      date_of_birth: "",
      email: "",
      first_name: "",
      from: "",
      from_city: null,
      from_country: null,
      gender: null,
      hear_from: "",
      house_number: "",
      introduction: "",
      is_active: true,
      last_login: "",
      last_name: "",
      learn_language: [],
      living_in_city: null,
      living_in_country: null,
      my_package: [],
      native_language: null,
      phone: "",
      postcode: "",
      skype_id: "",
      street_name: "",
      timezone: null,
      transactions_history: [],
      voucher_transactions: [],
      // Appointment history 
      appointment_history_data: [],
      appointment_history_pages: 1,
      appointment_history_isLoading: false,
      appointment_history_selected: {},
      appointment_history_totalAppointments: 0,
      appointment_history_sync: false,
      appointment_history_notification: {},
      appointment_history_errors: {},
      // Package transaction history 
      package_order_data: [],
      package_order_pages: 1,
      package_order_isLoading: false,
      package_order_selected: {},
      package_order_totalAppointments: 0,
      package_order_sync: false,
      package_order_notification: {},
      package_order_errors: {},
      // Voucher transaction history 
      voucher_order_data: [],
      voucher_order_pages: 1,
      voucher_order_isLoading: false,
      voucher_order_selected: {},
      voucher_order_totalAppointments: 0,
      voucher_order_sync: false,
      voucher_order_notification: {},
      voucher_order_errors: {},
    };
  };

  componentWillMount = () => {
    if(isEmpty(this.state.student_id)) {
      this.props.history.push('/users/students');
    } else {
      this.getSingleStudent();
    }
  };

  getSingleStudent = () => {
    this.setState({ isLoading: true, notification: {} });
    this.props.userGetSingleStudent({ _id: this.state.student_id }).then(res => {
      if(res.status === 200) {
        this.setState({
          isLoading: false,
          avatar_display: res.message.data.avatar_display,
          appointments: res.message.data.appointments,
          avatar_filename: res.message.data.avatar_filename,
          bank_number: res.message.data.bank_number,
          bridge_language: res.message.data.bridge_language,
          created_date: res.message.data.created_date,
          credit_balance: res.message.data.credit_balance.toFixed(2),
          credit_expired: res.message.data.credit_expired,
          date_of_birth: res.message.data.date_of_birth,
          email: res.message.data.email,
          first_name: res.message.data.first_name,
          from: res.message.data.from,
          from_city: res.message.data.from_city,
          from_country: res.message.data.from_country,
          gender: res.message.data.gender,
          hear_from: res.message.data.hear_from,
          house_number: res.message.data.house_number,
          introduction: res.message.data.introduction,
          is_active: res.message.data.is_active,
          last_login: res.message.data.last_login,
          last_name: res.message.data.last_name,
          learn_language: res.message.data.learn_language,
          living_in_city: res.message.data.living_in_city,
          living_in_country: res.message.data.living_in_country,
          my_package: res.message.data.my_package,
          native_language: res.message.data.native_language,
          phone: res.message.data.phone,
          postcode: res.message.data.postcode,
          skype_id: res.message.data.skype_id,
          street_name: res.message.data.street_name,
          timezone: res.message.data.timezone,
          transactions_history: res.message.data.transactions_history,
          voucher_transactions: res.message.data.voucher_transactions,
        })
      } else {
        this.setState({
          isLoading: false,
          notification: {
            text: 'We are experiencing technical difficulties at the moment, please try again later',
            isError: true
          }
        });
      };
    }).catch(err => {
      console.log(err);
      this.setState({
        isLoading: false,
        notification: {
          isError: true,
          text: "Internal server error. Please try again later."
        }
      });
    });
  };

  goBack = () => {
    this.props.history.goBack()
  };

  editProfileHandler = () => {
    this.setState({ onEditProfile : true })
  };

  closeEditProfileHandler = () => {
    this.setState({ onEditProfile: false });
    this.getSingleStudent();
  };

  editAccountHandler = () => {
    this.setState({ onEditAccount : true })
  };

  onAddCreditHandler = () => {
    this.setState({ onAddCredit : true })
  };

  closeEditAccountHandler = () => {
    this.setState({ onEditAccount: false });
    this.getSingleStudent();
  };

  closeAddCreditHandler = () => {
    this.setState({ onAddCredit: false });
    this.getSingleStudent();
  };

  deleteAccountHandler = () => {
    this.setState({ isLoading_delete: true, notification: {} });
    this.props.userDeleteStudent({ _id: this.state.student_id }).then(res => {
      if(res.status === 200) {
        this.setState({ isLoading_delete: false });
        this.goBack();
      } else {
        this.setState({
          isLoading_delete: false,
          notification: {
            text: res.message,
            isError: true
          }
        });
      };
    }).catch(err => {
      console.log(err);
      this.setState({ 
        isLoading_delete: false,
        notification: {
          text: "Internal server error. Please try again later.",
          isError: true
        }
      });
    });
  };

  render() {
    const { history, match } = this.props;

    const {
      isLoading,
      notification,
      onEditAccount,
      onEditProfile,
      onAddCredit,
      student_id,
      default_avatar,
      avatar_display,
      learn_language,
      bridge_language,
      credit_balance,
      credit_expired,
      date_of_birth,
      email,
      first_name,
      from_city,
      from_country,
      house_number,
      last_name,
      living_in_city,
      living_in_country,
      phone,
      postcode,
      street_name,
      timezone,
      // hear_from,
      // native_language,
    } = this.state;

    const learn_language_edit = learn_language.map(item => {
      return item.language;
    });

    const bridge_language_edit = bridge_language.map(item => {
      return item.language;
    });

    if(history.location.state) {
      return (
        <div>
          <div className='card mx-15'>
            <div className='card-header flex-column justify-content-center'>
              <div role='button' onClick={this.goBack} id='muted-text-group' className='position-absolute d-flex align-items-center cursor-pointer' style={{left: 34}}>
                <i className='ion-chevron-left cursor-pointer fs-30 mr-20'></i>
              </div>
              <h1 className=''>
                Student details
              </h1>
              <div 
                role='button' 
                onClick={this.deleteAccountHandler} 
                id='muted-text-group' 
                className='position-absolute d-flex align-items-center cursor-pointer letter-spacing-0 btn btn-danger' 
                style={{ right: 34, borderRadius: 25 }}
              >
                <i className='fa fa-trash cursor-pointer fs-16 mr-1'></i>
                Delete account
              </div>
            </div>
          </div>
          { isLoading ? 
            <div className='d-flex align-items-center justify-content-center' style={{ minHeight: "50vh"}}>
              <div className='text-center'>
                <LoadingGift />
                <p>Collecting student's data</p>
              </div>
            </div>
            : <div>
              <div className='px-15'>
                <Notification notification={notification} />
              </div>
              <div className='row mx-auto'>
                <div className='col-md-6'>
                  <div className='card card-body px-20 py-20'>
                    <h4 className='mb-0 text-center'>Profile</h4>
                    <hr className='my-20' />
                    <div className='px-20 mb-20'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <div 
                            alt="" 
                            className='h-70px w-70px' 
                            style={{
                              backgroundImage: `url("${avatar_display ? avatar_display : default_avatar}")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              borderRadius: '50%', 
                              boxShadow: "0px 2px 15px #d7d7d7"
                            }}
                          />
                          <div className='ml-30'>
                            <p className='mb-0 fs-14 fw-500'>{`${first_name} ${last_name}`}</p>
                            <p className='mb-0 fs-14 fw-500'>{email}</p>
                            <p className='mb-0 fs-14 fw-500'>{phone ? phone : '-'}</p>
                          </div>
                        </div>
                        <div>
                          { onEditProfile ? null 
                            : <button className='btn btn-main-orange btn-round' onClick={this.editProfileHandler}>Edit Profile</button>
                          }
                        </div>
                      </div>
                    </div>
                    <hr className='mb-20 mt-0'/>
                    { onEditProfile ? 
                      <StudentEditProfile
                        student_id={student_id} 
                        first_name={first_name}
                        last_name={last_name}
                        living_in_country={living_in_country}
                        living_in_city={living_in_city}
                        timezone={timezone}
                        date_of_birth={date_of_birth}
                        from_country={from_country}
                        from_city={from_city}
                        phone={phone}
                        bridge_language={bridge_language_edit}
                        learn_language={learn_language_edit}
                        closeEditing={this.closeEditProfileHandler}
                      /> 
                      : <StudentProfileDetails 
                        date_of_birth={date_of_birth}
                        from_country={from_country}
                        from_city={from_city}
                        phone={phone}
                        bridge_language={bridge_language}
                        learn_language={learn_language}
                      />
                    }
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='card card-body px-20 py-20'>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                      <h4 className='mb-0 text-center'>Account Details</h4>
                      { onEditAccount ? 
                        null
                        : <button 
                          className='btn btn-main-orange btn-round position-absolute' 
                          style={{right: 30}} 
                          onClick={this.editAccountHandler}
                        >
                          Edit Account
                        </button>
                      }
                    </div>
                    <hr className='my-20' />
                    <div className='px-20 mt-20 mb-40'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <img 
                            alt="" 
                            className='h-30px' 
                            src='/assets/img/icon/flowently-credit.svg'
                          />
                          <div className='d-flex flex-column ml-2'>
                            <h5 className='mb-0'>{credit_balance} flowently credits</h5>
                            { credit_balance && credit_balance > 0 &&
                              <p className='mb-0 lh-1 fs-13 font-weight-bold text-black'>Expiry date : {moment(credit_expired, 'MM/DD/YYYY').format('DD MMM YYYY')}</p>
                            }
                          </div>
                        </div>
                        { onAddCredit ? null
                          : <button 
                            className='btn btn-main-orange btn-round' 
                            onClick={this.onAddCreditHandler}
                          >
                            Edit credit
                          </button>
                        }
                      </div>
                    </div>
                    <hr className='mb-20 mt-0'/>
                    { onEditAccount ? 
                      <StudentEditAccount 
                        student_id={student_id}
                        country={living_in_country}
                        city={living_in_city}
                        street_name={street_name}
                        house_number={house_number}
                        postcode={postcode}
                        closeEditing={this.closeEditAccountHandler}
                      />
                      : <StudentAccountDetails 
                        living_in_country={living_in_country}
                        living_in_city={living_in_city}
                        street_name={street_name}
                        house_number={house_number}
                        postcode={postcode}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="px-15 bg-transparent my-30">
            <div className='' id="student-appointment-history">
              <StudentHistoryAppointment match={match} history={history} student_id={student_id} />
            </div>
          </div>
          <div className="px-15 bg-transparent my-30">
            <div className='' id="student-transaction-history">
              <StudentPackageOrdersHistory match={match} history={history} student_id={student_id} />
            </div>
          </div>
          <div className="px-15 bg-transparent my-30">
            <div className='' id="student-transaction-history">
              <StudentVoucherOrdersHistory match={match} history={history} student_id={student_id} />
            </div>
          </div>
          <div className="px-15 bg-transparent my-30">
            <div className='' id="student-credit-history">
              <StudentCreditHistory match={match} history={history} student_id={student_id} />
            </div>
          </div>
          { onAddCredit ? 
            <EditCreditModal
              student_id={student_id}
              credit_balance={credit_balance}
              credit_expired={credit_expired}
              closeEditing={this.closeAddCreditHandler}
              onCloseModal={() => this.setState({ onAddCredit: false })}
            />
          : null }
        </div>
      );
    } else {
      return <Redirect to='/users/students'/>;
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { userGetSingleStudent, userDeleteStudent })(StudentDetails);