import React, { Component } from 'react';
import Rating from 'react-rating';
import moment from 'moment';
import { isEmpty, sumBy } from 'lodash';
import { Redirect } from 'react-router-dom';
import MDSpinner from 'react-md-spinner';

import LoadingGift from '../../../common/LoadingGift';
import Notification from '../../../common/Notification';
import TutorEditProfile from './tutorEditProfile';
import TutorProfileDetails from './tutorProfileDetails';
import TutorAccountDetails from './tutorAccountDetails';
import TutorEditEmergeContact from './tutorEditEmergeContact';
import TutorEditAccountDetails from './tutorEditAccountDetails';
import TutorEmergeContactDetails from './tutorEmergeContactDetails';

import { connect } from 'react-redux';
import { userGetSingleTutor, userEditTutor, userUpdateActiveStatus, userDeleteTutor } from '../../../../actions/tutorsActions';

class TutorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutor_id: this.props.history.location.state,
      isLoading: false,
      isLoading_approvement: false,
      notification: {},
      notification_approvement: {},
      isLoadingEditProfile: false,
      notificationEditProfile: {},
      onEditingProfile: false,
      onEditingAccount: false,
      onEditingEmergencyContact: false,
      active_tab: 'tutor-appointment-history',
      default_avatar: '/assets/img/icon/male.svg',
      // Data from DB
      avatar_display: "",
      appointments: [],
      avatar_filename: "",
      bank_number: "",
      total_earning: 0,
      total_hours: 0,
      created_date: "",
      date_of_birth: "",
      email: "",
      first_name: "",
      last_name: "",
      display_name: "",
      from: "",
      living_in_country: null,
      living_in_city: null,
      gender: null,
      hear_from: "",
      house_number: "",
      is_active: false,
      is_approved: false,
      is_deleted: false,
      last_login: "",
      teach_language: [],
      bridge_language: [],
      day_available: [],
      time_available: [],
      session_type: [],
      country: [],
      city: [],
      closeAppointment: false,
      timezone: "",
      topics: [],
      total_rating: 0,
      reviews: [],
      reviews_visible: 4,
      youtube_link: "",
      cv_link: "",
      motivation_link: "",
      native_language: null,
      introduction: "",
      background: "",
      phone: "",
      postcode: "",
      skype_id: "",
      street_name: "",
      transactions_history: [],
      // Emergency Contact 
      full_name_ec: "",
      email_ec: "",
      phone_ec: "",
      country_ec: "",
      city_ec: "",
      street_name_ec: "",
      house_number_ec: "",
      postcode_ec: "",
      // Appointment history 
      appointment_history_data: [],
      appointment_history_pages: 1,
      appointment_history_isLoading: false,
      appointment_history_selected: {},
      appointment_history_totalAppointments: 0,
      appointment_history_sync: false,
      appointment_history_notification: {},
      appointment_history_errors: {},
      // Transaction history 
      transaction_history_data: [],
      transaction_history_pages: 1,
      transaction_history_isLoading: false,
      transaction_history_selected: {},
      transaction_history_totalAppointments: 0,
      transaction_history_sync: false,
      transaction_history_notification: {},
      transaction_history_errors: {},
    };

    this.reviewsCardRef = React.createRef();
  };

  componentWillMount = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    if(isEmpty(this.state.tutor_id)) {
      this.props.history.push('/users/tutors');
    } else {
      this.getSingleTutor();
    }
  };

  getSingleTutor = () => {
    this.setState({ isLoading: true, notification: {} });
    this.props.userGetSingleTutor({ _id: this.state.tutor_id }).then(res => {
      if(res.status === 200) {
        
        const success_appointments = res.message.data.appointments.filter(appointment => appointment.booking_status === 'success' || appointment.booking_status === 'confirmed');

        const total_earning = sumBy(success_appointments, function(data) {
          return data.book_price
        });

        const total_hours = sumBy(success_appointments, function(data) {
          return data.session_duration/60;
        });

        const {
          avatar_display,
          appointments,
          avatar_filename,
          bank_number,
          created_date,
          date_of_birth,
          email,
          first_name,
          last_name,
          display_name,
          country,
          city,
          living_in_country,
          living_in_city,
          gender,
          hear_from,
          house_number,
          introduction,
          background,
          is_active,
          is_deleted,
          is_approved,
          closeAppointment,
          day_available,
          time_available,
          session_type,
          topics,
          total_rating,
          reviews,
          youtube_link,
          cv_link,
          motivation_link,
          last_login,
          teach_language,
          bridge_language,
          phone,
          postcode,
          skype_id,
          street_name,
          timezone,
          transactions_history,
          full_name_ec,
          email_ec,
          phone_ec,
          country_ec,
          city_ec,
          street_name_ec,
          house_number_ec,
          postcode_ec,
        } = res.message.data;

        this.setState({
          isLoading: false,
          avatar_display,
          appointments,
          avatar_filename,
          bank_number,
          created_date,
          date_of_birth,
          email,
          first_name,
          last_name,
          display_name,
          country,
          city,
          living_in_country,
          living_in_city,
          gender,
          hear_from,
          house_number,
          introduction,
          background,
          is_active,
          is_deleted,
          is_approved,
          closeAppointment,
          day_available,
          time_available,
          session_type,
          topics,
          total_rating,
          total_earning,
          total_hours,
          reviews,
          youtube_link,
          cv_link,
          motivation_link,
          last_login,
          teach_language,
          bridge_language,
          phone,
          postcode,
          skype_id,
          street_name,
          timezone,
          transactions_history,
          // Emergency Contact 
          full_name_ec,
          email_ec,
          phone_ec,
          country_ec,
          city_ec,
          street_name_ec,
          house_number_ec,
          postcode_ec,
        });
      } else {
        this.setState({
          isLoading: false,
          notification: {
            isError: true,
            text: "We are experiencing technical difficulties at the moment, please refresh the page or try again later."
          }
        });
      };
    }).catch(err => {
      console.log(err);
      this.setState({
        isLoading: false,
        notification: {
          isError: true,
          text: "Internal server error. Please try again later."
        }
      });
    });
  };

  goBack = () => {
    this.props.history.goBack()
  };

  // onChangeActiveTab = value => {
  //   this.setState({ active_tab: value })
  // };

  editProfileHandler = () => {
    this.setState({ onEditingProfile : true })
  };

  closeEditProfileHandler = () => {
    this.setState({ onEditingProfile: false });
    this.getSingleTutor();
  };

  editAccountHandler = () => {
    this.setState({ onEditingAccount : true })
  };

  closeEditAccountHandler = () => {
    this.setState({ onEditingAccount: false });
    this.getSingleTutor();
  };

  editEmergencyContactHandler = () => {
    this.setState({ onEditingEmergencyContact : true })
  };

  closeEditEmergencyContactHandler = () => {
    this.setState({ onEditingEmergencyContact: false });
    this.getSingleTutor();
  };

  scrollToReviews = () => {
    window.scrollTo(0, this.reviewsCardRef.current.offsetTop)
  };

  loadMoreReviewsHandle = () => {
    this.setState((prev) => {
      return {
        reviews_visible: prev.reviews_visible + 4
      };
    });
  };

  onSubmitApproveTutor = (e) => {
    e.preventDefault();
    this.setState({ isLoading_approvement: true, notification_approvement: {} });

    const dataToSubmit = {
      _id: this.state.tutor_id,
      data: {
        is_approved: true
      }
    };
    
    this.props.userEditTutor(dataToSubmit).then(res => {
      if(res.status === 200) {
        this.setState({ 
          isLoading_approvement: false,
        });
        this.getSingleTutor();
      } else {
        this.setState({
          isLoading_approvement: false,
          notification_approvement: {
            text: 'We are experiencing technical difficulties at the moment, please try again later',
            isError: true
          }
        });
      };
    });
  };

  updateActiveStatus = (value) => {
    this.setState({ isLoading_update_status : true })
    const dataToSubmit = {
      _id: this.state.tutor_id,
      is_active: value
    };

    this.props.userUpdateActiveStatus(dataToSubmit).then(res => {
      if(res.status === 200) {
        this.setState({ 
          isLoading_update_status: false,
          is_active: value 
        })
      } else {
        this.setState({ 
          isLoading_update_status: false
        })
      };
    });
  };

  deleteAccountHandler = () => {
    this.setState({ isLoading_delete: true, notification: {} });
    this.props.userDeleteTutor({ _id: this.state.tutor_id }).then(res => {
      if(res.status === 200) {
        this.setState({ isLoading_delete: false });
        this.goBack();
      } else {
        this.setState({
          isLoading_delete: false,
          notification: {
            text: res.message,
            isError: true
          }
        });
      };
    }).catch(err => {
      console.log(err);
      this.setState({ 
        isLoading_delete: false,
        notification: {
          text: "Internal server error. Please try again later.",
          isError: true
        }
      });
    });
  };

  render() {
    const { 
      history, 
    } = this.props;

    const {
      isLoading,
      isLoading_approvement,
      notification,
      notification_approvement,
      tutor_id,
      onEditingProfile,
      onEditingAccount,
      onEditingEmergencyContact,
      email,
      first_name,
      last_name,
      display_name,
      date_of_birth,
      phone,
      postcode,
      skype_id,
      street_name,
      house_number,
      country,
      city,
      living_in_country,
      living_in_city,
      gender,
      default_avatar,
      avatar_display,
      introduction,
      background,
      total_rating,
      total_earning,
      total_hours,
      reviews,
      reviews_visible,
      full_name_ec,
      email_ec,
      phone_ec,
      country_ec,
      city_ec,
      street_name_ec,
      house_number_ec,
      postcode_ec,
      timezone,
      // bank_number,
      bridge_language,
      teach_language,
      session_type,
      topics,
      day_available,
      time_available,
      youtube_link,
      cv_link,
      motivation_link,
      // hear_from,
      is_approved,
      is_active,
      is_deleted,
      closeAppointment,
    } = this.state;

    if(history.location.state) {

      const approvement_status = is_approved ? 'Approved' : 'Not approved'
      let account_status = is_active ? 'Active' : !is_active && is_deleted ? 'Blocked' : 'Not active'

      let render_reviews = null;

      if(reviews && reviews.length > 0) {
        render_reviews = reviews.slice(0, reviews_visible).map((data, idx) => {
          return (
            <div className='col-md-6' key={`review-no-${idx}`} >
              <div className='card'>
                <div className='card-body p-20'>
                  <Rating
                    emptySymbol="fa fa-star-o fs-12 star-rating text-gray"
                    fullSymbol="fa fa-star fs-12 star-rating text-yellow"
                    initialRating={data.rating}
                    readonly
                  />
                  { !isEmpty(data.review) ? 
                    <p className='mb-0 fs-14 font-weight-bold'>{data.review.title}</p>
                  : null }
                  { !isEmpty(data.review) ? 
                    <p className='mb-0 fs-12 font-weight-normal'>{data.review.description}</p>
                  : null }
                  <div className='d-flex justify-content-between align-items-center'>
                    <p className='mb-0 fs-10' style={{color: "rgba(0, 0, 0, 0.5)"}}>{moment(data.review_date, 'MM/DD/YYYY').format('MMM Do, YYYY')}</p>
                    <div className='d-flex align-items-center'>
                      <p className='mb-0 font-weight-bold fs-12 mr-2'>
                        {data.author.first_name} {data.author.last_name}
                      </p>
                      <img 
                        alt='' 
                        style={{width: "30px", height: "30px", borderRadius: '50%', boxShadow: "0px 2px 15px #e7e7e7", maxWidth: '35px'}}
                        src={!isEmpty(data.author.avatar_display) ? data.author.avatar_display : default_avatar} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }

      return (
        <div>
          <Notification notification={notification} />
          <div className='card mx-15'>
            <div className='card-header flex-column justify-content-center'>
              <div role='button' onClick={this.goBack} id='muted-text-group' className='position-absolute d-flex align-items-center cursor-pointer' style={{left: 34}}>
                <i className='ion-chevron-left cursor-pointer fs-30 mr-20'></i>
              </div>
              <h1 className=''>
                Tutor details
              </h1>
              <div id='muted-text-group' className='position-absolute d-flex align-items-center cursor-pointer' style={{right: 34}}>
                { is_active ? 
                  <button onClick={() => this.updateActiveStatus(false)} className='btn btn-danger' style={{borderRadius: 25}}>Deactivate account</button>
                  : <button onClick={() => this.updateActiveStatus(true)} className='btn btn-main-orange' style={{borderRadius: 25}}>Activate account</button> 
                }
                <button onClick={this.deleteAccountHandler} className='btn btn-danger ml-2' style={{borderRadius: 25}}>
                  <i className='fa fa-trash cursor-pointer fs-16 mr-1'></i>
                  Delete account
                </button>
              </div>
            </div>
          </div>
          { isLoading ? 
            <div className='d-flex align-items-center justify-content-center' style={{ minHeight: "50vh"}}>
              <div className='text-center'>
                <LoadingGift />
                <p>Collecting tutor's data</p>
              </div>
            </div>
            : <div> 
              <div className='row mx-auto'>
                <div className='col-md-12'>
                  <div className='card card-body px-20 py-20'>
                    <div className='row my-20 px-15'>
                      <div className='col-md-3'>
                        <div className='d-flex align-items-center'>
                          <h5 className='mr-2 mb-0'>Approvement status :</h5>
                          <h5 
                            className='fs-14 mb-0 text-white px-3 py-2'
                            style={{
                              backgroundColor: is_approved ? '#57d500' : 'gray',
                              borderRadius: 10,
                              border: 0
                            }}
                          >
                            {approvement_status}
                          </h5>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='d-flex align-items-center'>
                          <h5 className='mr-2 mb-0'>Account status :</h5>
                          <h5 
                            className='fs-14 mb-0 text-white px-3 py-2'
                            style={{
                              backgroundColor: is_active ? '#57d500' : !is_active && is_deleted ? '#ff2e00' : 'grey',
                              borderRadius: 10,
                              border: 0
                            }}
                          >
                            {account_status}
                          </h5>
                        </div>
                      </div>
                      <div className='col-md-2'>
                        <h5 className='mr-2 mb-0'>Total Rating :</h5>
                        <div className='d-flex align-items-center'>
                          <Rating
                            emptySymbol="fa fa-star-o fs-14 star-rating text-gray"
                            fullSymbol="fa fa-star fs-14 star-rating text-yellow"
                            initialRating={total_rating}
                            readonly
                          />
                          <p 
                            onClick={this.scrollToReviews}
                            className='fs-14 text-info cursor-pointer reviews-link mb-0 ml-2'
                          >
                            ( {reviews.length === 0 ? `0 review` : `${reviews.length} reviews`} )
                          </p>
                        </div>
                      </div>
                      <div className='col-md-2'>
                        <h5 className='mr-2 mb-0'>Total Duration :</h5>
                        <div className='d-flex align-items-center'>
                          <i className='ion-android-time mr-2 fs-20'></i>
                          <h5 className='mb-0'>{total_hours} {total_hours > 1 ? 'hours' : 'hour'}</h5>
                        </div>
                      </div>
                      { is_approved ? 
                        <div className='col-md-2'>
                          <h5 className='mb-0'>Total earning : </h5>
                          <div className='d-flex align-items-center'>
                            <img src='/assets/img/icon/flowently-credit.svg' alt='' className='h-20px mr-2' />
                            <h5 className='mb-0'>{total_earning} {total_earning > 1 ? 'credits' : 'credit'}</h5>
                          </div>
                        </div>
                      : null }
                    </div>
                  </div>
                </div>
              </div>
              <Notification notification={notification_approvement} />
              { is_approved ? 
                null 
                : <div className='row mx-auto'>
                  <div className='col-md-12'>
                    <div className='card card-body px-20 py-20'>
                      <div className='row my-20 px-15'>
                        <div className='col-md-4'>
                          <div className='d-flex align-items-center'>
                            <h5 className='mr-2 mb-0'>CV file :</h5>
                            <a className="mb-0 fs-16 btn btn-info btn-round" href={cv_link} target="_blank" >
                              See CV
                            </a>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='d-flex align-items-center'>
                            <h5 className='mr-2 mb-0'>Motivation letter file :</h5>
                            <a className="mb-0 fs-16 btn btn-info btn-round" href={motivation_link} target="_blank" >
                              See Motivation Letter
                            </a>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          { isLoading_approvement ? 
                            <MDSpinner />
                            : <button onClick={this.onSubmitApproveTutor} className='btn btn-main-orange btn-round float-right'>Approve this tutor</button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className='row mx-auto'>
                {/* PROFILE DETAILS */}
                <div className='col-md-6'>
                  <div className='card card-body px-20 py-20'>
                    <h4 className='mb-0 text-center'>Profile</h4>
                    <hr className='my-20' />
                    <div className='px-20 mb-20'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <div 
                            alt="" 
                            className='h-70px w-70px' 
                            style={{
                              backgroundImage: `url("${avatar_display ? avatar_display : default_avatar}")`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              borderRadius: '50%', 
                              boxShadow: "0px 2px 15px #d7d7d7"
                            }}
                          />
                          <div className='ml-30'>
                            <p className='mb-0 fs-14 fw-500'>{display_name && !isEmpty(display_name) ? `${display_name}` : `${first_name} ${last_name}`}</p>
                            <p className='mb-0 fs-14 fw-500'>{email}</p>
                            <p className='mb-0 fs-14 fw-500'>{phone ? phone : '-'}</p>
                          </div>
                        </div>
                        <div>
                          { onEditingProfile ? null 
                            : <button className='btn btn-main-orange btn-round' onClick={this.editProfileHandler}>Edit Profile</button>
                          }
                        </div>
                      </div>
                    </div>
                    <hr className='mb-20 mt-0'/>
                    { onEditingProfile ? 
                      <TutorEditProfile 
                        tutor_id={tutor_id}
                        first_name={first_name}
                        last_name={last_name}
                        display_name={display_name}
                        email={email}
                        phone={phone}
                        country={country}
                        city={city}
                        date_of_birth={date_of_birth}
                        gender={gender}
                        skype_id={skype_id}
                        closeAppointment={closeAppointment}
                        teach_language={teach_language}
                        bridge_language={bridge_language}
                        session_type={session_type}
                        topics={topics}
                        day_available={day_available}
                        time_available={time_available}
                        timezone={timezone}
                        youtube_link={youtube_link}
                        background={background}
                        introduction={introduction}
                        closeEditing={this.closeEditProfileHandler}
                      />
                      : <TutorProfileDetails 
                        date_of_birth={date_of_birth}
                        gender={gender}
                        skype_id={skype_id}
                        country={country}
                        city={city}
                        closeAppointment={closeAppointment}
                        teach_language={teach_language}
                        bridge_language={bridge_language}
                        session_type={session_type}
                        topics={topics}
                        day_available={day_available}
                        time_available={time_available}
                        timezone={timezone}
                        background={background}
                        introduction={introduction}
                      />
                    }
                  </div>
                </div>
                {/* ACCOUNT DETAILS */}
                <div className='col-md-6'>
                  <div className='card card-body px-20 py-20'>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                      <h4 className='mb-0 text-center'>Account Details</h4>
                      { onEditingAccount ? null 
                        : <button 
                          className='btn btn-main-orange btn-round position-absolute' 
                          style={{right: 30}} 
                          onClick={this.editAccountHandler}
                        >
                          Edit Account
                        </button>
                      }
                    </div>
                    <hr className='my-20'/>
                    {/* <hr className='mb-20 mt-0'/> */}
                    { onEditingAccount ? 
                      <TutorEditAccountDetails 
                        tutor_id={tutor_id}
                        country={living_in_country}
                        city={living_in_city}
                        street_name={street_name}
                        house_number={house_number}
                        postcode={postcode}
                        closeEditing={this.closeEditAccountHandler}
                      /> 
                      : <TutorAccountDetails 
                        country={living_in_country}
                        city={living_in_city}
                        street_name={street_name}
                        house_number={house_number}
                        postcode={postcode}
                      /> 
                    }
                  </div>
                  <div className='card card-body px-20 py-20'>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                      <h4 className='mb-0'>
                        { onEditingEmergencyContact ? 
                          "Edit Emergency Contact"
                          : "Emergency Contact"
                        }
                      </h4>
                      { onEditingEmergencyContact ? 
                        null
                        : <button 
                          className='btn btn-main-orange btn-round position-absolute' 
                          style={{right: 30}} 
                          onClick={this.editEmergencyContactHandler}
                        >
                          Edit
                        </button>
                      }
                    </div>
                    <hr className='my-20' />
                    { onEditingEmergencyContact ? 
                      <TutorEditEmergeContact 
                        tutor_id={tutor_id}
                        full_name_ec={full_name_ec}
                        email_ec={email_ec}
                        phone_ec={phone_ec}
                        country_ec={country_ec}
                        city_ec={city_ec}
                        street_name_ec={street_name_ec}
                        house_number_ec={house_number_ec}
                        postcode_ec={postcode_ec}
                        closeEditing={this.closeEditEmergencyContactHandler}
                      />
                      : <TutorEmergeContactDetails
                        full_name_ec={full_name_ec}
                        email_ec={email_ec}
                        phone_ec={phone_ec}
                        country_ec={country_ec}
                        city_ec={city_ec}
                        street_name_ec={street_name_ec}
                        house_number_ec={house_number_ec}
                        postcode_ec={postcode_ec}
                      />
                    }
                  </div>
                </div>
              </div>
              <div ref={this.reviewsCardRef} className='row mx-auto'>
                <div className='col-md-12'>
                  <div className='card card-body px-20 py-20'>
                    <h4 className='mb-0 text-center'>Reviews</h4>
                    <hr className='mt-20 mb-40'/>
                    { reviews && reviews.length > 0 ? 
                      <div className='row mx-20'>
                        {render_reviews}
                      </div>
                      : <div className='text-center'>
                        <p className='mb-0 my-40'>There are no reviews yet.</p>
                      </div>
                    }
                    {reviews_visible < reviews.length &&
                      <div className='text-center'>
                        <button onClick={this.loadMoreReviewsHandle} className='btn btn-main-orange btn-round letter-spacing-0'>
                          Load more reviews
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      );
    } else {
      return <Redirect to='/users/tutors'/>;
    }
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { userGetSingleTutor, userEditTutor, userUpdateActiveStatus, userDeleteTutor })(TutorDetails);