import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

const Notification = ({ notification }) => {
	return (
		<div> { !isEmpty(notification) ? <div className={classnames("alert", {"alert-danger" : notification.isError} , {"alert-success" : !notification.isError} )}>{notification.text}</div> 
	 		: null}
	 	</div>
		);
}

Notification.propTypes = {
	notification: PropTypes.object.isRequired,
}


export default Notification;