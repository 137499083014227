import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';


export default function validateInput(data) {
	let errors = {};
	if(Validator.isEmpty(data.new_password)){
		errors.new_password = "Please insert new password";
	}
	if(Validator.isEmpty(data.repeat_new_password)){
		errors.repeat_new_password = "Please repeat new password";
	}
	if (data.new_password !== data.repeat_new_password ) {
		errors.repeat_new_password = "Your new password doesn't match";
	}
	return {
		errors,
		isValid: isEmpty(errors)
	}
};
