import isEmpty from "lodash/isEmpty";
import moment from 'moment';

export function validateStudentProfile(data) {
  let errors = {}

  // if(isEmpty(data.first_name)) {
  //   errors.first_name = 'First name is required'
  // }
  // if(isEmpty(data.last_name)) {
  //   errors.last_name = 'Last name is required'
  // }
  // if(isEmpty(data.last_name)) {
  //   errors.last_name = 'Last name is required'
  // }
  // if(isEmpty(data.gender)) {
  //   errors.gender = 'Gender is required'
  // }
  // if(isEmpty(data.learn_language)) {
  //   errors.learn_language = 'Please select at least 1'
  // }
  // if(isEmpty(data.bridge_language)) {
  //   errors.bridge_language = 'Please select at least 1'
  // }

  if(!isEmpty(data.date_of_birth)) {
    const regex = RegExp(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/gm);
    const date_of_birth = moment(data.date_of_birth, "MM/DD/YYYY").format('DD-MM-YYYY');
    const result = regex.test(date_of_birth);
    if(!result) {
      errors.date_of_birth = "Date is not valid. Format : MM-DD-YYYY" 
    } else {
      const separated_date = date_of_birth.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
      if(separated_date) {
        const selected_year = separated_date[3];
        const selected_month = separated_date[1];
        const selected_date = separated_date[2];
        const date_now = moment().format('DD');
        const month_now = moment().format('MM');
        const year_now = moment().format('YYYY');
        if(selected_year > year_now) {
            errors.date_of_birth = `Max date is ${date_now}-${month_now}-${year_now}`;
        } 
        if(selected_year === year_now) {
          if(selected_month > month_now) errors.date_of_birth = `Max date is ${date_now}-${month_now}-${year_now}`;
        }
        if(selected_year === year_now && selected_month === month_now) {
          if(selected_date > date_now) errors.date_of_birth = `Max date is ${date_now}-${month_now}-${year_now}`;
        }
      } else {
        errors.date_of_birth = "Date is not valid. Format : MM-DD-YYYY" 
      }
    };
  }
  
  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function validateStudentAccount(data) {
  let errors = {}

  if(isEmpty(data.country)) {
    errors.country = 'Please select country you lives in'
  } else {
    if(isEmpty(data.city)) {
      errors.city = 'Please select country you lives in'
    }
  }
  
  if(isEmpty(data.street_name)) {
    errors.street_name = 'Street name is required'
  }
  if(isEmpty(data.house_number)) {
    errors.house_number = 'House number is required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
};

export function validateStudentEditCredit(data) {
  let errors = {};

  if(isEmpty(data.edited_credit)) {
    errors.edited_credit = 'Please insert credit you want to add'
  } else {
    if(isNaN(data.edited_credit)) {
      errors.edited_credit = 'Please input only number'
    } else {
      // eslint-disable-next-line
      if(parseFloat(data.edited_credit) < 0) {
        errors.edited_credit = 'Credit should not less than 0'
      };

      const splitComma = data.edited_credit.split('.');
      
      if(splitComma.length > 2) {
        errors.edited_credit = 'Please input only with a single comma'
      };
    };
  };
  
  if(isEmpty(data.credit_expired)) {
    errors.credit_expired = 'Credit expired date is required'
  };

  if(isEmpty(data.credit_expired)) {
    errors.credit_expired = 'Credit expiry date is required'
  } else {
    const regex = RegExp(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/gm);
    const credit_expired = moment(data.credit_expired, "MM/DD/YYYY").format('DD-MM-YYYY');
    const result = regex.test(credit_expired);
    if(!result) {
      errors.credit_expired = "Date is not valid. Format : MM-DD-YYYY" 
    } else {
      const separated_date = credit_expired.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
      if(separated_date) {
        const selected_year = separated_date[3];
        const selected_month = separated_date[1];
        const selected_date = separated_date[2];
        const date_now = moment().format('DD');
        const month_now = moment().format('MM');
        const year_now = moment().format('YYYY');
        if(selected_year < year_now) {
          errors.credit_expired = `Min date is ${date_now}-${month_now}-${year_now}`;
        } 
        if(selected_year === year_now) {
          if(selected_month < month_now) errors.credit_expired = `Min date is ${date_now}-${month_now}-${year_now}`;
        }
        if(selected_year === year_now && selected_month === month_now) {
          if(selected_date < date_now) errors.credit_expired = `Min date is ${date_now}-${month_now}-${year_now}`;
        }
      } else {
        errors.credit_expired = "Date is not valid. Format : MM-DD-YYYY" 
      }
    };
  };

  return {
    errors,
    isValid: isEmpty(errors),
  };
};