import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Languages from './language/languages';

class Manages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_tab: this.props.tab_id,
    };
  };
  
  onChangeActiveOverview = value => {
    this.setState({ active_tab : value });
  };
  
  render() {
    // const { 
    //   active_tab
    // } = this.state;

    const { history, match } = this.props;

    return (
      <div id='users-overview'>
        <div className='card card-body p-0'>
          <div className="text-center">
            <h2 className='my-20'>Manage data</h2>
          </div>
          {/* <ul className="nav nav-tabs nav-justified my-40">
            <li className="nav-item mx-2">
              <Link 
                to={`/manage/cities`}
                onClick={() => this.onChangeActiveOverview('cities')}
                className={`nav-link fs-16 ${active_tab === 'cities' ? 'active' : '' }`}
              >
                Cities
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link 
                to={`/manage/languages`}
                onClick={() => this.onChangeActiveOverview('languages')}
                className={`nav-link fs-16 ${active_tab === 'languages' ? 'active' : '' }`}
              >
                Languages
              </Link>
            </li>
          </ul> */}
        </div>
        <div className="card card-body p-0">
          <Languages history={history} match={match} />
        </div>
      </div>
    );
  };
};

export default Manages;