import React, { Component } from "react";
import ReactTable from "react-table";
import Notification from '../../../common/Notification';

import { connect } from "react-redux";
import { userGetStudentCreditHistory } from '../../../../actions/studentsActions';

class StudentCreditHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_id: this.props.student_id,
      data: [],
      pages: 1,
      isLoading: false,
      selected: {},
      totalRecords: 0,
      sync: false,
      notification: {},
      errors: {},
    };
    this.table = React.createRef();
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.modal.sync) {
      if (nextProps.modal.sync !== this.props.modal.sync) {
        this.getCreditHistory(this.table.current.state);
      }
      return true;
    }
    return true;
  };

  getCreditHistory = (state, instance) => {
    this.setState({ isLoading: true, table_filter: state.filtered, notification: {} });
    // const fromDate = moment(this.state.from_date).subtract("days", 1);
    // const toDate = moment(this.state.to_date).add("days", 1);
    this.props.userGetStudentCreditHistory({
      pagination: {
        pageSize: state.pageSize,
        page: state.page,
      },
      sorted: state.sorted,
      filtered: state.filtered,
      student_id: this.state.student_id
    }).then((res) => {
      if (res.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "We are experiencing technical difficulties at the moment, please try again later",
            isError: true,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          data: res.message.data,
          loading: false,
          pages: res.message.totalPage,
          totalRecords: res.message.totalRecords,
        });
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
    });
  };
  
  render() {
    const {
      data,
      pages,
      isLoading,
      notification,
			totalRecords
    } = this.state;

    return (
      <div className="card card-body p-30">
        <h1 className="text-center">Credit history</h1>
        <div className="main-view">
					<p className="text-right fs-16 fw-500 mr-10 py-10">
            Total : {totalRecords} {totalRecords > 1 ? 'records' : 'record'}
          </p>
          <Notification notification={notification} />
          <ReactTable
            filterable
            defaultFiltered={
              this.props.creditHistoryTableFilter ? this.props.creditHistoryTableFilter : []
            }
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={[
              {
                Header: "Type",
                accessor: "type",
                sortable: false,
								Filter: ({ filter, onChange }) => (
                  <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%", height: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">All</option>
                    <option value="package">Package order</option>
                    <option value="voucher">Gift voucher</option>
                    <option value="refund">Refund</option>
                    <option value="cancelation">Session cancelation</option>
                    <option value="session">Session</option>
                    <option value="edited">Edited</option>
                  </select>
                ),
                Cell: (row) => {
                  return (
                    <span className='text-capitalize'>
                      {
												row.value === "package" ? 'Package order'
												: row.value === "voucher" ? 'Gift voucher'
												: row.value === "refund" ? 'Session expired'
												: row.value === "cancelation" ? 'Session cancelation'
												: row.value
											}
                    </span>
                  );
                },
                style: { textAlign: "center" },
              },
              {
                Header: "Amount",
                accessor: "amount",
                sortable: false,
                filterable: false,
                Cell: (row) => {
                  return (
                    <div 
                      className={
												row.original.type === 'session' ? "text-danger fs-14" 
												: row.original.type === 'edited' ? "text-warning fs-14" 
												: "text-success fs-14"
											}
                    >
                      {row.value} {Number(row.value) === 1 || Number(row.value) === -1 ? 'credit' : 'credits'}
                    </div>
                  );
                },
                style: { textAlign: "center" },
              },
              {
                Header: "Credit before",
                accessor: "credit_before",
                sortable: false,
                filterable: false,
                Cell: (row) => {
                  return (
                    <div>
                      { row.value ?
                        `${row.value} ${Number(row.value) === 1 || Number(row.value) === -1 ? 'credit' : 'credits'}`
                      : '-' }
                    </div>
                  );
                },
                style: { textAlign: "center" },
              },
              {
                Header: "Credit after",
                accessor: "credit_after",
                sortable: false,
                filterable: false,
                Cell: (row) => {
                  return (
                    <div>
                      { row.value ?
                        `${row.value} ${Number(row.value) === 1 || Number(row.value) === -1 ? 'credit' : 'credits'}`
                      : '-' }
                    </div>
                  );
                },
                style: { textAlign: "center" },
              },
              {
                Header: "Created date",
                accessor: "created_date",
                sortable: false,
                filterable: false,
                Cell: (row) => (
                  <span>
                    {row.value}
                  </span>
                ),
                style: { textAlign: "center" },
              },
              {
                Header: "Created by",
                accessor: "admin",
                sortable: false,
                filterable: false,
                Cell: (row) => (
                  <span>
										{ row.value ? 
                    	`${row.value.first_name} ${row.value.last_name}`
										: 'Student' }
                  </span>
                ),
                style: { textAlign: "center" },
              },
            ]}
            ref={this.table}
            data={data} // should default to []
            pages={pages} // should default to -1 (which means we don't know how many pages we have)
            loading={isLoading}
            manual // informs React Table that you'll be handling sorting and pagination server-side
            onFetchData={this.getCreditHistory}
            defaultPageSize={10}
            showPagination={true}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    modal: state.modal,
  }
};

export default connect(mapStateToProps, { userGetStudentCreditHistory })(StudentCreditHistory);