import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';


export default function validateInput(data) {
	let errors = {};
	
	if(Validator.isEmpty(data.email)){
		errors.email = "Please insert your email/username";
	}
	return {
		errors,
		isValid: isEmpty(errors)
	}
};
