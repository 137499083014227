import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';
import { isEmpty, remove } from 'lodash';

import Notification from '../../../common/Notification';
import SwitchButton from '../../../common/SwitchButton';
import CustomCheckbox from '../../../common/CustomCheckbox';
import SelectFieldGroup from '../../../common/SelectFieldGroup';
import TextAreaFieldGroup from '../../../common/TextAreaFieldGroup';
import SelectLanguageFieldGroup from '../../../common/SelectLanguageFieldGroup';
import TextFieldGroupLogin from '../../../common/TextFieldGroupLogin';
import MultiSelectFieldGroup from '../../../common/MultiSelectFieldGroup';

import { connect } from 'react-redux';
import { userGetAllLanguages, userGetAllWorldCountries, userGetCountryDetails } from '../../../../actions/commonActions';
import { userEditTutor } from '../../../../actions/tutorsActions';
import { validateTutorProfile } from '../../../common/validations/tutorEdit'; 
import { session_type_options, timezone_options, topics_options } from '../../../common/SelectOptionsData';

class TutorEditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      errors: {
        country: [null],
        city: [null]
      },
      language_options: [],
      tutor_id: this.props.tutor_id,
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      display_name: this.props.display_name,
      email: this.props.email,
      phone: this.props.phone,
      date_of_birth: this.props.date_of_birth,
      skype_id: this.props.skype_id,
      youtube_link: this.props.youtube_link,
      closeAppointment: this.props.closeAppointment,
      teach_language: this.props.teach_language,
      bridge_language: this.props.bridge_language,
      session_type: this.props.session_type,
      topics: this.props.topics,
      day_available: this.props.day_available,
      time_available: this.props.time_available,
      timezone: this.props.timezone,
      background: this.props.background,
      introduction: this.props.introduction,
      country: this.props.country,
      city: this.props.city,
      country_options: [],
      city_options: [],
    };
  };

  componentDidMount = () => {
    this.getAllLanguages();
    this.getAllWorldCountries();

    if(this.props.country && !isEmpty(this.props.country)) {
      this.setState({ isLoading: true });
      let new_city_options = [];
      this.props.country.map((item, idx) => {
        new_city_options.push({ city: [] });
        
        this.props.userGetCountryDetails({ country_code: item.value }).then((res) => {
          if(!isEmpty(res.message.data)) {
            new_city_options[idx] = { city : res.message.data[0].city_options };
          };
          this.setState({ 
            city_options: new_city_options,
            isLoading: false
          });
        }).catch((error) => {
          console.log(error)
          this.setState({ isLoading: false });
        });

        return item;
      });
    } else {
      this.setState({
        city_options: [{city: []}]
      })
    };
  };

  getAllWorldCountries = () => {
    this.props.userGetAllWorldCountries().then(res => {
      if(res.status === 200) {
        this.setState({
          country_options: res.message.data
        });
        return res.message.data;
      }
    }).catch(err => {
      console.log(err)
    });
  };

  getAllLanguages = () => {
    this.props.userGetAllLanguages().then(res => {
      if(res.status === 200) {
        this.setState({
          language_options: res.message.data
        });
        return res.message.data;
      }
    }).catch(err => {
      console.log(err)
    });
  };

  onChangeDate = date => {
    this.setState({
      ...this.state,
      date_of_birth: date,
    });
  };

  onChangeSelect = e => {
    this.setState({ 
      ...this.state,
      [e.target.name] : e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name] : null
      }
    })
  };

  onChangeInput = e => {
    this.setState({ 
      ...this.state,
      [e.target.name] : e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name] : null
      }
    })
  };

  onChangeDayAvailability = (e) => {
    const { day_available } = this.state;

    let new_data = [...day_available];
    
    if(day_available.includes(e.target.name)) {
      new_data = remove(day_available, item => {
        return item !== e.target.name
      })
    } else {
      new_data.push(e.target.name)
    }

    this.setState({ day_available: new_data });
  };

  onChangeTimeAvailability = e => {
    const { time_available } = this.state;

    let new_data = [...time_available];
    
    if(time_available.includes(e.target.name)) {
      new_data = remove(time_available, item => {
        return item !== e.target.name
      })
    } else {
      new_data.push(e.target.name)
    }

    this.setState({ 
      time_available: new_data
    });
  };

  onChangeSwitch = () => {
    this.setState({ closeAppointment: !this.state.closeAppointment })
  };

  isValid = () => {
    const { errors, isValid } = validateTutorProfile(this.state);
    if(!isValid){
      this.setState({ errors });
    }
    return isValid;
  };

  addCityHandler = () => {
    const { country, city, city_options } = this.state;
    const added_data = null;

    this.setState({
      country: country.concat([added_data]),
      city: city.concat([added_data]),
      city_options: city_options.concat({ city: [] })
    });
  };

  removeCityHandler = (idx) => {
    const { country, city } = this.state;
    this.setState({ 
      country: country.filter((s, sidx) => idx !== sidx),
      city: city.filter((s, sidx) => idx !== sidx) 
    });
  };

  onChangeCountry = (val, idx) => {
    const { country, city, city_options, errors } = this.state;

    const new_city_options = city_options.map((item, sidx) => {
      if(idx !== sidx) {
        return item;
      } else {
        return {
          ...item,
          city: val.city_options
        }
      }
    });

    const new_country = country.map((item, sidx) => {
      if(idx !== sidx) {
        return item;
      } else {
        return {
          value: val.value,
          label: val.label
        };
      }
    });

    if(country.length === 0 && idx === 0) {
      new_country.push({
        value: val.value,
        label: val.label
      });
    };

    const new_city = city.map((item, sidx) => {
      if(idx !== sidx) {
        return item;
      } else {
        return null
      }
    });

    const country_error = errors.country.map((item, sidx) => {
      if(idx !== sidx) {
        return item;
      } else {
        return null;
      }
    });

    this.setState({
      country: new_country,
      city: new_city,
      errors: {
        ...errors,
        country: country_error
      },
      city_options: new_city_options
    });
  };

  onChangeCity = (value, idx) => {
    const { city, errors } = this.state;
    const new_data = city.map((item, sidx) => {
      if(idx !== sidx) {
        return item
      } else {
        return value;
      }
    });

    if(city.length === 0 && idx === 0) {
      new_data.push(value)
    };

    const city_error = errors.city.map((item, sidx) => {
      if(idx !== sidx) {
        return item;
      } else {
        return null;
      }
    });

    this.setState({
      city: new_data,
      errors: {
        ...errors,
        city: city_error
      }
    });
  };

  onSubmitEditProfile = (e) => {
    e.preventDefault();
    const { 
      tutor_id,
      first_name,
      last_name,
      display_name,
      email,
      phone,
      youtube_link,
      closeAppointment,
      teach_language,
      bridge_language,
      session_type,
      topics,
      day_available,
      time_available,
      timezone,
      background,
      introduction,
      country,
      city,
    } = this.state;

    this.setState({ isLoading: true, notification: {}, errors: {} });

    const dataToSubmit = {
      _id: tutor_id,
      data: {
        first_name,
        last_name,
        timezone,
        display_name,
        email,
        phone,
        youtube_link,
        closeAppointment,
        teach_language,
        bridge_language,
        session_type,
        topics,
        day_available,
        time_available,
        background,
        introduction,  
        country,
        city
      }
    };
    if(this.isValid()) {
      this.props.userEditTutor(dataToSubmit).then(res => {
        if(res.status === 200) {
          this.setState({ 
            isLoading: false,
          });
          this.props.closeEditing();
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true
            }
          });
        };
      });
    } else {
      this.setState({
        isLoading: false,
        notification: {
          text: 'Please complete the form above',
          isError: true
        }
      });
    };
  };

  render() {
    const { 
      isLoading,
      notification,
      errors,
      language_options,
      first_name,
      last_name,
      display_name,
      email,
      phone,
      youtube_link,
      closeAppointment,
      teach_language,
      bridge_language,
      session_type,
      topics,
      day_available,
      time_available,
      timezone,
      background,
      introduction,
      country,
      city,
      country_options,
      city_options
    } = this.state;

    let render_city = null;

    if(city && city.length > 0) {
      render_city = city.map((item, idx)=> {
        return (
          <div key={`City-${idx+1}`} className='row m-auto'>
            <div className={idx === 0 ? 'col-md-6 p-0' : 'col-md-6 p-0'}>
              <div className='pr-10'>
                <SelectLanguageFieldGroup 
                  loadingRender={isLoading}
                  label='Country'
                  labelClass={idx > 0 ? "d-none" : ''}
                  labelWeightNormal={true}
                  value={country[idx]}
                  options={country_options}
                  onChange={(val) => this.onChangeCountry(val, idx)}
                  error={errors && errors.country ? errors.country[idx] : null}
                  isClearable={false}
                  isDisabled={isLoading}
                  isSearchable={true}
                />
              </div>
            </div>
            <div className={idx === 0 ? 'col-md-6 p-0' : 'col-md-5 p-0'}>
              <div className='pl-10'>
                <SelectLanguageFieldGroup 
                  loadingRender={isLoading}
                  label='City'
                  labelClass={idx > 0 ? "d-none" : ''}
                  labelWeightNormal={true}
                  value={item}
                  options={city_options[idx] && city_options[idx].city ? city_options[idx].city : []}
                  onChange={(val) => this.onChangeCity(val, idx)}
                  error={errors && errors.city ? errors.city[idx] : null}
                  isClearable={false}
                  isDisabled={isLoading}
                  isSearchable={true}
                />
              </div>
            </div>
            { idx > 0 ?
              <div className={'col-md-1 d-flex align-items-start p-0'}>
                <button
                  className='btn button-transparent border-0 text-black cursor-pointer'
                  style={{height: 42}}
                  onClick={() => this.removeCityHandler(idx)}
                >
                  <i className='ion-close-round cursor-pointer'></i>
                </button>
              </div>
            : null }
          </div>
        );
      });
    };

    return (
      <div>
        <h4 className="text-center">Edit profile</h4>
        <hr className='my-20'/>
        <div className="row m-auto">
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Email"
              field="email"
              value={email}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.email}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Display Name"
              field="display_name"
              value={display_name}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.display_name}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="First Name"
              field="first_name"
              value={first_name}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.first_name}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Last Name"
              field="last_name"
              value={last_name}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.last_name}
            />
          </div>
          <div className="col-md-6">
            <SelectFieldGroup
              label="Time zone"
              value={timezone}
              options={timezone_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: "timezone", value: val },
                })
              }
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={true}
              error={errors.timezone}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Phone"
              placeholder="Your phone number"
              field="phone"
              value={phone}
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.phone}
            />
          </div>
          <div className="col-md-12">
            { city.length > 0 ? render_city 
              : <div className='row mx-auto'>
                <div className='col-md-6 p-0'>
                  <div className='pr-15'>
                    <SelectLanguageFieldGroup 
                      loadingRender={isLoading}
                      label='Country'
                      labelWeightNormal={true}
                      value={country[0]}
                      options={country_options}
                      onChange={(val) => this.onChangeCountry(val, 0)}
                      error={errors && errors.country ? errors.country[0] : null}
                      isClearable={false}
                      isDisabled={isLoading}
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className='col-md-6 p-0'>
                  <div className='pl-15'>
                    <SelectLanguageFieldGroup 
                      loadingRender={isLoading}
                      label='City'
                      labelWeightNormal={true}
                      value={city[0]}
                      options={city_options[0] ? city_options[0].city :  city_options}
                      onChange={(val) => this.onChangeCity(val, 0)}
                      error={errors && errors.city ? errors.city[0] : null}
                      isClearable={false}
                      isDisabled={isLoading}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>
            }
            { city.length <= 4 ?
              <div className='text-center'>
                <button 
                  onClick={this.addCityHandler} 
                  className='btn button-transparent border-transparent m-auto align-items-center px-15 mb-20'
                  style={{ display: 'inline-flex' }}
                >
                  <i className='ion-plus-round text-info fs-14 mr-1 cursor-pointer'></i>
                  <label className='fs-14 mb-0 text-info cursor-pointer'>Add more city</label>
                </button>
              </div>
            : null }
          </div>
          <div className='col-md-12'>
            <MultiSelectFieldGroup 
              label="Session Available"
              value={session_type}
              options={session_type_options}
              onChange={(val) =>
                this.onChangeSelect({ target: { name: "session_type", value: val } })
              }
              error={errors.session_type}
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={false}
            />
          </div>
          <div className="col-md-12">
            <MultiSelectFieldGroup
              label="Teach Language"
              value={teach_language}
              options={language_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: "teach_language", value: val },
                })
              }
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={true}
              error={errors.teach_language}
            />
          </div>
          <div className="col-md-12">
            <MultiSelectFieldGroup
              label="Bridge Language"
              value={bridge_language}
              options={language_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: "bridge_language", value: val },
                })
              }
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={true}
              error={errors.bridge_language}
            />
          </div>
          <div className='col-md-12'>
            <MultiSelectFieldGroup 
              label='Topics Available'
              value={topics}
              options={topics_options}
              onChange={val => this.onChangeSelect({target: {name: 'topics', value: val}})}
              error={errors.topics}
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={false}
            />
          </div>
          <div className='col-md-12'>
            <TextFieldGroupLogin
              label="Professional background (max. 10 words)"
              field="background"
              value={background}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.background}
            />
          </div>
          <div className='col-md-12'>
            <TextAreaFieldGroup
              label="Introduction (max. 100 words)"
              field="introduction"
              value={introduction}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.introduction}
            />
          </div>
          <div className='col-md-12'>
            <TextFieldGroupLogin
              label="Introduction Video"
              placeholder="https://youtube.com/your-video-id"
              field="youtube_link"
              value={youtube_link}
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.youtube_link}
            />
          </div>
          <div className='col-md-12'>
            <div className='h-90px'>
              <label className="col-form-label font-weight-bold fs-15">Availability</label>
              <div className='row mx-auto mt-1 mb-10'>
                <div className='col-md-3 px-0'>
                  <label className="mb-10 d-flex align-items-center">
                    <CustomCheckbox
                      name='weekdays'
                      checked={day_available.includes('weekdays')}
                      onChange={this.onChangeDayAvailability}
                      disabled={isLoading}
                    />
                    <span className="fw-400 ml-2 fs-14 position-relative">
                      Weekdays
                    </span>
                  </label>
                </div>
                <div className='col-md-3 px-0'>
                  <label className="mb-10 d-flex align-items-center">
                    <CustomCheckbox
                      name='weekend'
                      checked={day_available.includes('weekend')}
                      onChange={this.onChangeDayAvailability}
                      disabled={isLoading}
                    />
                    <span className="fw-400 ml-2 fs-14 position-relative">
                      Weekends
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='h-90px'>
              <div className='h-90px'>
                <label className="col-form-label font-weight-bold letter-spacing-0 fs-15">What time of the day are you generally available ?</label>
                <div className='row mx-auto mt-1 mb-10'>
                  <div className='col-md-4 px-0'>
                    <label className="mb-10 d-flex align-items-center">
                      <CustomCheckbox
                        name='morning'
                        checked={time_available.includes('morning')}
                        onChange={this.onChangeTimeAvailability}
                        disabled={isLoading}
                      />
                      <span className="fw-400 ml-2 fs-14 position-relative">
                        Morning ( 08:00 - 12:00 )
                      </span>
                    </label>
                  </div>
                  <div className='col-md-4 px-0'>
                    <label className="mb-10 d-flex align-items-center">
                      <CustomCheckbox
                        name='day'
                        checked={time_available.includes('day')}
                        onChange={this.onChangeTimeAvailability}
                        disabled={isLoading}
                      />
                      <span className="fw-400 ml-2 fs-14 position-relative">
                        Afternoon ( 12:00 - 18:00 )
                      </span>
                    </label>
                  </div>
                  <div className='col-md-4 px-0'>
                    <label className="mb-10 d-flex align-items-center">
                      <CustomCheckbox
                        name='evening'
                        checked={time_available.includes('evening')}
                        onChange={this.onChangeTimeAvailability}
                        disabled={isLoading}
                      />
                      <span className="fw-400 ml-2 fs-14 position-relative">
                        Evening ( 18:00 - 22:00 )
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='d-flex justify-content-between align-items-center'>
              <label className="col-form-label font-weight-bold letter-spacing-0 fs-15">Currently not accepting new students</label>
              <div>
                <SwitchButton
                  value={closeAppointment}
                  onChange={this.onChangeSwitch}
                />
              </div>
            </div>
            <p className='mb-0 fs-14' style={{color: "rgba(0, 0, 0, 0.7)"}}>If you set it ON, only NEW students will not be able to make an appointment with you</p>
          </div>
          <div className="col-md-12 my-10">
            <Notification notification={notification} />
          </div>
        </div>
        <hr className='my-20' />
        { isLoading ? 
          <div className='d-flex align-items-center justify-content-end'>
            <MDSpinner />
          </div>
          : <div className='d-flex align-items-center justify-content-end'>
            <button onClick={this.onSubmitEditProfile} className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>Save changes</button>
            <button onClick={() => this.props.closeEditing()} className='btn border-orange btn-round letter-spacing-0'>Cancel</button>
          </div>
        }
      </div>
    );
  }
}

export default connect(null, { userEditTutor, userGetAllLanguages, userGetAllWorldCountries, userGetCountryDetails })(TutorEditProfile);