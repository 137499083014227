import React, { Component } from "react";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";

import Notification from '../../../common/Notification';

import { connect } from "react-redux";
import { userGetStudentVoucherOrdersHistory } from '../../../../actions/studentsActions';
import StudentVoucherOrderDetailsModal from "./studentVoucherOrderDetailsModal";

class StudentVoucherOrdersHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_id: this.props.student_id,
      data: [],
      pages: 1,
      isLoading: false,
      selected: {},
      totalTransactions: 0,
      sync: false,
      notification: {},
      errors: {},
      transactionModalOpen: false,
      transactionSelected: {}
    };
    this.table = React.createRef();
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.modal.sync) {
      if (nextProps.modal.sync !== this.props.modal.sync) {
        this.getTransactionList(this.table.current.state);
      }
      return true;
    }
    return true;
  };

  getTransactionList = (state, instance) => {
    this.setState({ isLoading: true, table_filter: state.filtered, notification: {} });
    // const fromDate = moment(this.state.from_date).subtract("days", 1);
    // const toDate = moment(this.state.to_date).add("days", 1);
    this.props.userGetStudentVoucherOrdersHistory({
      pagination: {
        pageSize: state.pageSize,
        page: state.page,
      },
      sorted: state.sorted,
      filtered: state.filtered,
      student_id: this.state.student_id
    }).then((res) => {
      if (res.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "Some features has been updated, please logout and login again",
            isError: true,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          data: res.message.data,
          loading: false,
          pages: res.message.totalPage,
          totalTransactions: res.message.totalTransactions,
        });
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
    });
  };

  showTransactionDetailsModal = selected => {
    console.log(selected)
    this.setState({
      transactionModalOpen: true,
      transactionSelected: selected
    });
  };
  
  onCloseTransactionModal = () => {
    this.setState({
      transactionModalOpen: false,
      transactionSelected: {}
    });
  };
  
  render() {
    const {
      data,
      pages,
      isLoading,
      notification,
      totalTransactions,
      transactionModalOpen,
      transactionSelected
    } = this.state;

    return (
      <div className="card card-body p-30">
        <h1 className="text-center">Voucher orders history</h1>
        <div className="main-view">
          <p className="text-right fs-16 fw-500 mr-10 py-10">
            Total : {totalTransactions} {totalTransactions > 1 ? 'transactions' : 'transaction'}
          </p>
          <Notification notification={notification} />
          <ReactTable
            filterable
            defaultFiltered={
              this.props.salesTableFilter ? this.props.salesTableFilter : []
            }
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={[
              {
                Header: "Order ID",
                accessor: "order_id",
                sortable: false,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["order_id"] }),
                filterAll: true,
                style: { textAlign: "center" },
              },
              {
                Header: "Voucher name",
                accessor: "description",
                sortable: false,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["description"] }),
                Cell: (props) => {
                    const package_name = props.value.charAt(0).toUpperCase() + props.value.slice(1);
                    return (
                        <span>{package_name}</span>
                    )
                },
                filterAll: true,
                style: { textAlign: "center" },
              },
              {
                Header: "Voucher price",
                accessor: "amount.value",
                sortable: false,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["amount.value"] }),
                Cell: (props) => {
                    return (
                        <span>&euro; {props.value}</span>
                    )
                },
                filterAll: true,
                style: { textAlign: "center" },
              },
              {
                Header: "Payment status",
                accessor: "payment_status",
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%", height: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">All</option>
                    <option value="open">Open</option>
                    <option value="pending">Pending</option>
                    <option value="paid">Paid</option>
                    <option value="authorized">Authorized</option>
                    <option value="canceled">Canceled</option>
                    <option value="failed">Failed</option>
                    <option value="expired">Expired</option>
                  </select>
                ),
                Cell: (row) => (
                  <span 
                    className="fw-500"
                    style={{
                      color: row.value === "open"
                      ? "#d9bc00"
                      : row.value === "paid"
                      ? "#15db1f"
                      : row.value === "canceled"
                      ? "red"
                      : "gray"
                    }}
                  >
                    {row.value.charAt(0).toUpperCase() + row.value.slice(1)}
                  </span>
                ),
                style: { textAlign: "center" },
              },
              {
                Header: "Payment method",
                accessor: "payment_method",
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%", height: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">All</option>
                    <option value="ideal">Ideal</option>
                    <option value="creditcard">Credit card</option>
                  </select>
                ),
                Cell: (row) => (
                  <span className="text-capitalized fw-500">
                    {row.value === 'ideal' ? "Ideal" : row.value === 'creditcard' ? "Credit card" : row.value.charAt(0).toUpperCase() + row.value.slice(1)}
                  </span>
                ),
                style: { textAlign: "center" },
              },
              {
                Header: "Transaction date ( MM/DD/YYYY )",
                accessor: "created_date",
                sortable: false,
                filterAll: true,
                filterable: true,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["created_date"],
                  }),
                Cell: (props) => {
                  return <span>{props.row.created_date}</span>;
                },
                style: {
                  textAlign: "center",
                  flex: "264.562 0 auto!important",
                  width: "264.562px!important",
                  maxWidth: "264.562px!important",
                },
              },
              {
                Header: "",
                accessor: "_id",
                sortable: false,
                filterable: false,
                Cell: (row) => (
                  <span>
                    <button onClick={()=>this.showTransactionDetailsModal(row.original)}  className="btn-round btn-info cursor-pointer">
                      Details
                    </button>
                  </span>
                ),
                style: { textAlign: "center" },
              },
            ]}
            ref={this.table}
            data={data} // should default to []
            pages={pages} // should default to -1 (which means we don't know how many pages we have)
            loading={isLoading}
            manual // informs React Table that you'll be handling sorting and pagination server-side
            onFetchData={this.getTransactionList}
            defaultPageSize={10}
            showPagination={true}
            className="-striped -highlight"
          />
        </div>
        { transactionModalOpen ?  
          <StudentVoucherOrderDetailsModal 
            onCloseModal={this.onCloseTransactionModal}
            data={transactionSelected}
          />
        : null }
      </div>
    );
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    modal: state.modal,
  }
};

export default connect(mapStateToProps, { userGetStudentVoucherOrdersHistory })(StudentVoucherOrdersHistory);