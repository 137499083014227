import React from 'react';
import classnames from 'classnames';

const BenefitListInput = ({ idx, field, value, label, error, onChange, disabled, placeholder }) => {
    return (
		<div className={classnames("form-group row", {'has-error': error})}>
			<label className="col-form-label col-md-3">{label}</label>
            <div className="col-form-label px-0 col-md-1 d-flex align-items-start justify-content-center">
			    { label ? 
                    <p className='mb-0'>:</p>
                : null }
            </div>
			<div className="col-md-8 pl-0">
				<input
                    id={idx}
					value = {value}
					onChange = {onChange}
					type="text"
					name={field}
					className="form-control"
					disabled={disabled}
                    placeholder={placeholder}
				/>
			</div>
			{error && <span className="help-block">{error}</span>}
		</div>
	);
}

export default BenefitListInput;