import React from "react";
import { Route, Switch } from "react-router-dom";
import Main from "./components/Main";

import loginPage from "./components/login/loginPage";
import forgetPasswordPage from "./components/forget-password/forgetPasswordPage";
import resetPasswordPage from "./components/reset-password/resetPasswordPage";
import accountActivationPage from "./components/account-activation/accountActivationPage";

import dashboardPage from "./components/dashboard/dashboardPage";
import AppointmentPage from "./components/appointment/appointmentPage";
import UsersPage from "./components/users/usersPage";

import PrivateRoute from "./privateRoute";
import PackagePage from "./components/package/packagesPage";
import OrdersPage from "./components/order/ordersPage";
import SettingsPage from "./components/settings/settingsPage";
import ManagePage from "./components/manage/managePage";
import VoucherPage from "./components/vouchers/voucherPage";
import SessionPackagesPage from "./components/session-packages/sessionPackagesPage";
// import AdminRoute from './adminRoute';

class App extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/login" component={loginPage} />
          <Route exact path="/forget-password" component={forgetPasswordPage} />
          <Route exact path="/reset-password" component={resetPasswordPage} />
          <Route exact path="/activate-account" component={accountActivationPage} />

          <PrivateRoute path="/dashboard" component={dashboardPage} />
          <PrivateRoute path="/sessions" component={AppointmentPage} />
          <PrivateRoute path="/packages" component={PackagePage} />
          <PrivateRoute path="/session-packages" component={SessionPackagesPage} />
          <PrivateRoute path="/gift-cards" component={VoucherPage} />
          <PrivateRoute path="/users" component={UsersPage} />
          <PrivateRoute path="/orders" component={OrdersPage} />
          <PrivateRoute path="/manage" component={ManagePage} />
          <PrivateRoute path="/settings" component={SettingsPage} />

          <Route path="/" component={Main} />
        </Switch>
      </div>
    );
  }
}

export default App;
