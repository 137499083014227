export function validateEditVoucher(data) {
  const isEmpty = value =>
	value === undefined ||
	value === null ||
	value === "-" ||
	(typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);
  
  let errors = {};

  // eslint-disable-next-line
  if(parseInt(data.credit_value) === 0) {
    errors.credit_value = "Must be greater than 0";
  };

  // eslint-disable-next-line
  if(parseInt(data.sale_price) === 0) {
    errors.sale_price = "Must be greater than 0";
  };

  // eslint-disable-next-line
  if(parseInt(data.origin_price) === 0) {
    errors.origin_price = "Must be greater than 0";
  };

  if(isEmpty(data.is_active)) {
    errors.is_active = "Please select the voucher status";
  }

  if(isEmpty(data.title)) {
    errors.title = "Title is required";
  }

  if(isEmpty(data.description)) {
    errors.description = "Description is required";
  }
  
  if(isEmpty(data.credit_value)) {
    errors.credit_value = "Hours is required";
  } else {
    if(isNaN(data.credit_value)) {
      errors.credit_value = "Please insert number, decimal with (.)";
    };
  };

  if(isEmpty(data.sale_price)) {
    errors.sale_price = "Discount price is required";
  } else {
    if(isNaN(data.sale_price)) {
      errors.sale_price = "Please insert number, decimal with (.)";
    };
  };

  
  if (isEmpty(data.origin_price)) {
    errors.origin_price = "Origin price is required";
  } else {
    if(isNaN(data.origin_price)) {
      errors.origin_price = "Please insert number, decimal with (.)";
    };
  };
  
  return {
    errors,
    isValid: isEmpty(errors),
  };
};