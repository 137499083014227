import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';

import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './rootReducer';

import App from './app';
import { BrowserRouter as Router } from 'react-router-dom';

import { setCurrentUser, userLogout } from './actions/authActions';
import { saveState, loadState } from './utils/localStorage';

const persistedState = loadState();


const store = createStore(
	rootReducer,
	persistedState,
	compose(
		applyMiddleware(thunk),
		// window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
	)
)

store.subscribe(() => {
	saveState(store.getState());
})


localStorage.removeItem('jwtToken');
if (localStorage.token) {
	let token_expiration = localStorage.exp;	
	let current_time = Date.now() / 1000;
	if ( token_expiration < current_time) {
		delete axios.defaults.headers.common['Authorization'];
	 	store.dispatch(userLogout());
	 	window.location.reload();
	} else {
		axios.defaults.headers.common['Authorization'] = localStorage.token
		store.dispatch(setCurrentUser(JSON.parse(localStorage.user)));
	}
}

const root = createRoot(document.getElementById('app')); // createRoot(container!) if you use TypeScript
root.render(
	<Provider store={store}>
		<Router>
			<App />
		</Router>
	</Provider>
);