import React from 'react';
// import { Switch } from 'react-router-dom';
// import PrivateRoute from '../../privateRoute';
import Layout from '../layout/Layout';
import Manages from './views/manages';

class UsersPage extends React.Component {
	render() {
		const { history, match } = this.props;
		return(
			<Layout history={history}>
        <main className="main-container">
          <div className="main-content">
            {/* <Switch>
							<PrivateRoute path={`${match.path}/students/details`} component={StudentDetails}/> 
							<PrivateRoute path={`${match.path}/students`} component={StudentsTable}/> 
							<PrivateRoute path={`${match.path}/tutors/history`} component={TutorHistoryAppointment}/> 
							<PrivateRoute path={`${match.path}/tutors/details`} component={TutorDetails}/> 
							<PrivateRoute path={`${match.path}/languages`} component={TutorsTable}/> 
							<PrivateRoute path={`${match.path}`} component={Manages}/> 
			      </Switch> */}
            <Manages history={history} match={match} />
          </div>
        </main>
      </Layout>
		);
	}
}

export default UsersPage;