import React, { Component } from "react";

import TextArrayDetailsFieldGroup from "../../../common/TextArrayDetailsFieldGroup";
import TextFieldDetailsGroup from "../../../common/TextFieldDetailsGroup";

class TutorProfileDetails extends Component {
  render() {
    const {
      closeAppointment,
      teach_language,
      bridge_language,
      session_type,
      topics,
      day_available,
      time_available,
      timezone,
      background,
      introduction,
      country,
      city 
    } = this.props;

    let render_city = null;

    if(city.length > 0) {
      render_city = city.map((item, idx) => {
        return (
          <li key={`city-available-${idx}`}>
            <p className='mb-0 fw-600'>
              {item.label}, 
              <span className='ml-1 fw-600'>
                {country[idx].label}
              </span>
            </p>
          </li>
        );
      });
    };
    
    return (
      <div className="row mx-10">
        <div className="col-md-6">
          <TextArrayDetailsFieldGroup
            label="Teach language"
            arrayType="array_of_objects"
            value={teach_language.length > 0 ? teach_language : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextArrayDetailsFieldGroup
            label="Bridge language"
            arrayType="array_of_objects"
            value={bridge_language.length > 0 ? bridge_language : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextArrayDetailsFieldGroup
            label="Session type"
            arrayType="array_of_objects"
            value={session_type.length > 0 ? session_type : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextArrayDetailsFieldGroup
            label="Day availability"
            value={day_available.length > 0 ? day_available : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextArrayDetailsFieldGroup
            label="Time availability"
            value={time_available.length > 0 ? time_available : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Accepting new students"
            value={closeAppointment ? "NO" : "YES"}
            addStyle={{ color: closeAppointment ? "#ff2e00" : "#57d500" }}
          />
        </div>
        <div className="col-md-6">
          <label style={{ color: "#959595" }} className="mb-0 fs-14 fw-500">
            City availability
          </label>
          { city.length > 0 ?
            <ul className='pl-16'>
              {render_city}
            </ul>
            : <p className='fw-600'>-</p>
          }
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Timezone"
            value={timezone ? timezone.label : "-"}
          />
        </div>
        <div className="col-md-12">
          <TextArrayDetailsFieldGroup
            label="Topics"
            arrayType="array_of_objects"
            value={topics.length > 0 ? topics : "-"}
          />
        </div>
        <div className="col-md-12 border-top pt-20">
          <TextFieldDetailsGroup
            label="Background"
            value={background ? background : "-"}
          />
        </div>
        <div className="col-md-12">
          <TextFieldDetailsGroup
            label="Introduction"
            value={introduction ? introduction : "-"}
          />
        </div>
      </div>
    );
  }
}

export default TutorProfileDetails;
