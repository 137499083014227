import { API } from '../utils/apiConnect';
import { SET_CURRENT_USER, SET_TOKEN, SET_DATA } from './types';

export function setCurrentUser(user) {
	return {
		type : SET_CURRENT_USER,
		user
	};
}

export function setData(data) {
	return {
		type : SET_DATA,
		data
	};
}

export function setToken(token) {
	return {
		type : SET_TOKEN,
		token
	};
};

export function runLogoutTimer(dispatch, token_exp) {
	const token_exp_date = new Date(token_exp * 1000);
  const today_date = new Date();
	const timer = token_exp_date.getTime() - today_date.getTime(); // in milliseconds

	setTimeout(() => {
		dispatch(userLogout())
	}, timer);
};

export function userLogin(data) {
	return dispatch => {
		return API.post('/auth-crm/login', data )
		.then( res => {
			if (res.data.status === 200) {
				const {
					token,
					user
				} = res.data.message;
				localStorage.setItem('token', token.key);
				localStorage.setItem('exp', token.exp);
				localStorage.setItem('user', JSON.stringify(user));
				API.defaults.headers.common['Authorization'] = token.key;
				runLogoutTimer(dispatch, token.exp);
				dispatch(setCurrentUser(user))
				
			}
			return res.data;
		})
	}
}

export function userForgotPassword(data) {	
	return dispatch => {
		return API.post('/auth-crm/forgot', data )
		.then( res => {
			console.log(res.data);
			return res.data;
		})
	}
}
export function userResetPassword(data) {	
	return dispatch => {
		return API.post('/auth-crm/reset', data )
		.then( res => {
			console.log(res);
			return res.data;
		})
	}
}

export function userActivateAccount(data) {	
	return dispatch => {
		return API.post('/auth-crm/activate', data )
		.then( res => {
			console.log(res);
			return res.data;
		})
	}
}
export function userLogout(data) {
	return dispatch => {
		localStorage.removeItem('token');
		localStorage.removeItem('exp');
		localStorage.removeItem('user');
		dispatch(setCurrentUser({}));
	}
}

