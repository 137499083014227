import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class LeftPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.auth.user,
    };
  }
  render() {
    const { pathname } = this.props.history.location;
    return (
      <aside className="sidebar sidebar-expand-lg sidebar-iconic sidebar-light sidebar-color-primary">
        <header className="sidebar-header bg-white">
          <img src="/assets/img/logo.png" alt="logo icon" />
        </header>
        <nav className="sidebar-navigation" style={{overflow: "auto"}}>
          <ul className="menu menu-sm menu-bordery">
            <li className={pathname === '/dashboard' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/dashboard">
                <img alt="" className="w-25px" src="/assets/img/icon/dashboard.svg" />
                <span className="title">Dashboard</span>
              </Link>
            </li>
            <li className={pathname === '/sessions' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/sessions">
                <img alt="" className="" src="/assets/img/icon/book-open.svg" />
                <span className="title">Sessions</span>
              </Link>
            </li>
            <li className={pathname === '/users' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/users">
                <img alt="" className="" src="/assets/img/icon/users.svg" />
                <span className="title">Users</span>
              </Link>
            </li>
            <li className={pathname === '/packages' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/packages">
                <img alt="" className="" src="/assets/img/icon/packages.svg" />
                <span className="title">Packages</span>
              </Link>
            </li>
            <li className={pathname === '/session-packages' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/session-packages">
                <img alt="" className="w-25px" src="/assets/img/icon/shopping-bag.svg" />
                <span className="text-center mt-2" style={{lineHeight: 1.5}}>Session<br/>packages</span>
              </Link>
            </li>
            <li className={pathname === '/gift-cards' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/gift-cards">
                <div className='w-25px'>
                  <img alt="" className="" src="/assets/img/icon/gift-card.svg" />
                </div>
                <span className="title">Gift cards</span>
              </Link>
            </li>
            <li className={pathname === '/orders' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/orders">
                <img alt="" className="" src="/assets/img/icon/file-text.svg" />
                <span className="title">Orders</span>
              </Link>
            </li>
            <li className={pathname === '/manage' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/manage">
                <div className='w-25px'>
                  <img alt="" className="" src="/assets/img/icon/checklist.svg" />
                </div>
                <span className="title">Manage</span>
              </Link>
            </li>
            <li className={pathname === '/settings' ? "menu-item active" : "menu-item"}>
              <Link className="menu-link" to="/settings">
                <img alt="" className='w-25 manage-page-icon' src="/assets/img/icon/settings.svg" />
                <span className="title">Setting</span>
              </Link>
            </li>
          </ul>
        </nav>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}
export default connect(mapStateToProps)(LeftPane);
