import { isEmpty } from "lodash";
import React, { Component } from "react";
import TextFieldDetailsGroup from "../../../common/TextFieldDetailsGroup";

class TutorAccountDetails extends Component {
  render() {
    const { 
      country, 
      city, 
      street_name, 
      house_number, 
      postcode 
    } = this.props;

    return (
      <div className="row mx-10">
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Living in country"
            value={country && !isEmpty(country) ? country.label : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Living in city"
            value={city && !isEmpty(city) ? city.label : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Street name"
            value={street_name ? street_name : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="House number"
            value={house_number ? house_number : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Postal code"
            value={postcode ? postcode : "-"}
          />
        </div>
      </div>
    );
  };
};

export default TutorAccountDetails;
