import React from 'react';
import Layout from '../layout/Layout';
import SessionPackages from './views/sessionPackages';

class SessionPackagesPage extends React.Component {
	render() {
		const { history, match } = this.props;
		return(
			<Layout history={history}>
                <main className="main-container">
                	<div className="main-content">
                        <SessionPackages history={history} match={match} />
                	</div>
                </main>
             </Layout>
		);
	}
}

export default SessionPackagesPage;
