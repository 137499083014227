import React from "react";
import { connect } from "react-redux";
import { userLogout } from "../../../actions/authActions";
import PropTypes from "prop-types";

class TopHeader extends React.Component {
  logout(e) {
    e.preventDefault();
    this.props.userLogout();
    this.props.history.push("/login");
  }
  render() {
    return (
      <header className="topbar">
        <span className="topbar-btn sidebar-toggler">
          <i>☰</i>
        </span>
        <div className="topbar-left">
          <p className="topbar-btn text-dark fw-500 text-capitalize d-md-block">
            Name : {this.props.auth.user.name}
          </p>
          <p className="topbar-btn text-dark fw-500 d-md-block">
            Email : {this.props.auth.user.email}
          </p>
        </div>
        <div className="topbar-right">
          <a className="dropdown-item" onClick={this.logout.bind(this)}>
            <i className="ti-power-off text-reddit"></i> Logout
          </a>
        </div>
      </header>
    );
  }
}

TopHeader.propTypes = {
  userLogout: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { userLogout })(TopHeader);
