import React, { Component } from "react";

import TextFieldDetailsGroup from "../../../common/TextFieldDetailsGroup";
import TextArrayDetailsFieldGroup from "../../../common/TextArrayDetailsFieldGroup";

class StudentProfileDetails extends Component {
  render() {
    const {
      from_country,
      from_city,
      phone,
      bridge_language,
      learn_language,
    } = this.props;

    return (
      <div className="row mx-10">
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="From country"
            value={from_country ? from_country.label : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="From city"
            value={from_city ? from_city.label : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextArrayDetailsFieldGroup
            label="Learn language"
            arrayType="array_of_objects_student"
            value={learn_language.length > 0 ? learn_language : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextArrayDetailsFieldGroup
            label="Bridge language"
            arrayType="array_of_objects_student"
            value={bridge_language.length > 0 ? bridge_language : "-"}
          />
        </div>
        {/* <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Date of birth"
            value={
              date_of_birth
                ? moment(date_of_birth, "MM/DD/YYYY").format("MMM Do, YYYY")
                : "-"
            }
          />
        </div> */}
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Phone"
            value={phone ? phone : "-"}
          />
        </div>
      </div>
    );
  }
}

export default StudentProfileDetails;
