import React, { Component } from "react";
import { isEmpty } from "lodash";
import MDSpinner from "react-md-spinner";

import Notification from "../../../common/Notification";
import SelectFieldGroup from "../../../common/SelectFieldGroup";
import AsyncSelectFieldGroup from "../../../common/AsyncSelectFieldGroup";
import TextFieldGroupLogin from "../../../common/TextFieldGroupLogin";

import { connect } from 'react-redux';
import { validateTutorEmergencyContact } from '../../../common/validations/tutorEdit';
import { userGetAllCountries } from '../../../../actions/commonActions';
import { userEditTutor } from '../../../../actions/tutorsActions';

class TutorEditEmergeContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      errors: {},
      tutor_id: this.props.tutor_id,
      full_name_ec: this.props.full_name_ec,
      email_ec: this.props.email_ec,
      phone_ec: this.props.phone_ec,
      country_ec: this.props.country_ec,
      city_ec: this.props.city_ec,
      city_ec_options: [],
      street_name_ec: this.props.street_name_ec, 
      house_number_ec: this.props.house_number_ec, 
      postcode_ec : this.props.postcode_ec,
    };
  };

  componentWillMount = () => {
    this.setState({ isLoading: true, notification: {}, errors: {} });

    if(this.props.country_ec && !isEmpty(this.props.country_ec)) {
      const queryInputCountry = this.props.country_ec.label.toLowerCase();
      this.props.userGetAllCountries({query:queryInputCountry})
      .then((response) => {
        let city_ec_options = response.message.data[0].city_options;
        this.setState({ 
          isLoading: false,
          city_ec_options,
        });
      }).catch((error) => {
        console.log(error);
        this.setState({isLoading:false });
      });
    } else {
      this.setState({ isLoading: false });
    };
  };
  
  loadOptionsCountry = (inputValue, callback) => {
    this.props.userGetAllCountries({query:inputValue})
    .then((response) => {
        callback(response.message.data);
    }).catch((error) => {
       this.setState({isLoading:false });
    });
  };

  onChangeSelectCountry = (val) => {
    const country_ec = {
      value: val.value,
      label: val.label
    };

    const city_ec_options = val.city_options;

    this.setState({ country_ec, city_ec_options, city_ec: null });
  };

  onChangeSelect = (e) => {
    this.setState({ city_ec: e.target.value })
  };

  onChangeInput = (e) => {
    this.setState({ [e.target.name] : e.target.value })
  };

  isValid = () => {
    const { errors, isValid } = validateTutorEmergencyContact(this.state);
    if(!isValid){
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmitEditAccount = (e) => {
    e.preventDefault();
    const { 
      full_name_ec,
      email_ec,
      phone_ec,
      tutor_id,
      country_ec, 
      city_ec, 
      street_name_ec, 
      house_number_ec, 
      postcode_ec,
    } = this.state;

    this.setState({ isLoading: true, notification: {}, errors: {} });

    const dataToSubmit = {
      _id: tutor_id,
      data: {
        full_name_ec,
        email_ec,
        phone_ec,
        country_ec, 
        city_ec, 
        street_name_ec, 
        house_number_ec, 
        postcode_ec,
      }
    };
    if(this.isValid()) {
      this.props.userEditTutor(dataToSubmit).then(res => {
        if(res.status === 200) {
          this.setState({ 
            isLoading: false,
          });
          this.props.closeEditing();
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true
            }
          });
        };
      });
    } else {
      this.setState({
        isLoading: false,
        notification: {
          text: 'Please complete the form above',
          isError: true
        }
      });
    };
  };

  render() {
    const {
      full_name_ec,
      email_ec,
      phone_ec,
      country_ec, 
      city_ec, 
      city_ec_options,
      street_name_ec, 
      house_number_ec, 
      postcode_ec,
      isLoading,
      notification,
      errors
    } = this.state;

    return (
      <div>
        <div className="row m-auto">
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Full name"
              field="full_name_ec"
              value={full_name_ec}
              placeholder="Full name"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.full_name_ec}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Email"
              field="email_ec"
              value={email_ec}
              placeholder="Full name"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.email_ec}
            />
          </div>
          <div className="col-md-6">
            <AsyncSelectFieldGroup
              placeholder="Type to search"
              label="Country"
              value={country_ec}
              loadOptions={this.loadOptionsCountry}
              onChange={this.onChangeSelectCountry}
              error={errors.country_ec}
              isDisabled={isLoading}
              isClearable={false}
            />
          </div>
          <div className="col-md-6">
            <SelectFieldGroup
              label="City"
              value={city_ec}
              options={city_ec_options}
              onChange={(val) => this.onChangeSelect({ target: { name: "city_ec", value: val } })}
              error={errors.city_ec}
              isClearable={false}
              isDisabled={isLoading || isEmpty(country_ec)}
              isSearchable={true}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Street Name"
              field="street_name_ec"
              value={street_name_ec}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.street_name_ec}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="House Number"
              field="house_number_ec"
              value={house_number_ec}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.house_number_ec}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Postal code"
              field="postcode_ec"
              value={postcode_ec}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.postcode_ec}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Phone"
              field="phone_ec"
              value={phone_ec}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.phone_ec}
            />
          </div>
          <div className="col-md-12 my-10">
            <Notification notification={notification} />
          </div>
        </div>
        <hr className='my-20' />
        { isLoading ? 
          <div className='d-flex align-items-center justify-content-end'>
            <MDSpinner />
          </div>
          : <div className='d-flex align-items-center justify-content-end'>
            <button onClick={this.onSubmitEditAccount} className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>Save changes</button>
            <button onClick={() => this.props.closeEditing()} className='btn border-orange btn-round letter-spacing-0'>Cancel</button>
          </div>
        }
      </div>
    );
  }
}

export default connect(null, { userGetAllCountries, userEditTutor })(TutorEditEmergeContact);
