import React, { Component } from 'react';
import moment from 'moment';

import MDSpinner from 'react-md-spinner';
import Notification from '../../../common/Notification';
import DateSelectGroup from '../../../common/DateSelectGroup';
import TextFieldGroupLogin from '../../../common/TextFieldGroupLogin';

import { connect } from 'react-redux';
import { validateStudentEditCredit } from '../../../common/validations/studentEdit';
import { userEditStudent } from '../../../../actions/studentsActions';

class EditCreditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      errors: {},
      student_id: this.props.student_id,
      edited_credit: 0,
      credit_balance: 0,
      credit_expired: moment(new Date()).add(12, 'months').format("MM/DD/YYYY"),
    };
  };

  componentWillMount = () => {
    const { credit_balance, credit_expired } = this.props;
    
    if(credit_balance) {
      this.setState({ credit_balance, edited_credit: credit_balance });
    };

    if(credit_expired) {
      this.setState({ credit_expired });
    };
  };

  onChangeInput = (e) => {
    if(e.target.name === 'edited_credit') {
      const value = e.target.value.replace(',', '.');
      this.setState({ 
        [e.target.name] : value,
        errors: {
          ...this.state.errors,
          [e.target.name]: null
        } 
      })
    } else {
      this.setState({ 
        [e.target.name] : e.target.value,
        errors: {
          ...this.state.errors,
          [e.target.name]: null
        } 
      });
    }
  };

  isValid = () => {
    const { errors, isValid } = validateStudentEditCredit(this.state);
    if(!isValid){
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmitEditCredit = () => {
    this.setState({ isLoading: true, notification: {}, errors: {} });
    if(this.isValid()){
      const { student_id, credit_expired, edited_credit } = this.state;

      const dataToSubmit = {
        _id: student_id,
        adminID: this.props.auth.user.id,
        data: {
          credit_balance: parseFloat(edited_credit),
          credit_expired,
        },
      };

      this.props.userEditStudent(dataToSubmit).then(res => {
        if(res.status === 200) {
          this.setState({ 
            isLoading: false,
          });
          this.props.closeEditing();
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true
            }
          });
        };
      });

    } else {
      this.setState({
        isLoading: false,
        notification: {
          text: 'Please complete the form above',
          isError: true
        }
      });
    };
  };

  render() { 
    const {
      isLoading,
      errors,
      notification,
      edited_credit,
      credit_balance,
      credit_expired,
    } = this.state;

    return (
      <div>
        <div
          className="modal modal-center fade show"
          id="modal-center"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title text-center font-weight-bold">Edit credit</h4>
                <button onClick={this.props.onCloseModal} type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">
                    <i className="ion-close-round fs-16 cursor-pointer"></i>
                  </span>
                </button>
              </div>
              <div className='modal-body m-0 py-30 px-40'>
                <div className='d-flex align-items-center'>
                  <img 
                    alt="" 
                    className='h-30px' 
                    src='/assets/img/icon/flowently-credit.svg'
                  />
                  <div className='d-flex flex-column align-items-center justify-content-center ml-2'>
                    <h5 className='mb-0'>Recent : {credit_balance} flowently credits</h5>
                  </div>
                </div>
                <hr className='border-top my-20' />
                <TextFieldGroupLogin
                  field="edited_credit"
                  label="New credit"
                  value={edited_credit}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.edited_credit}
                />
                <DateSelectGroup
                  value={credit_expired}
                  label="Expiry date"
                  type="input-mask"
                  name="credit_expired"
                  onChange={this.onChangeInput}
                  isDisabled={isLoading}
                  error={errors.credit_expired}
                />
                <Notification notification={notification} />
              </div>
              <div className='modal-footer border-top p-20'>
                { isLoading ? 
                  <div className='d-flex align-items-center justify-content-end'>
                    <MDSpinner />
                  </div>
                  : <div className='d-flex align-items-center justify-content-end'>
                    <button onClick={this.onSubmitEditCredit} className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>Save</button>
                    <button onClick={this.props.onCloseModal} className='btn border-orange btn-round letter-spacing-0'>Cancel</button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { userEditStudent })(EditCreditModal);