import React from 'react'
import Select from 'react-select'
import classnames from 'classnames'

export default function SelectLanguageFieldGroup({
  label,
  error,
  value,
  options,
  onChange,
  isClearable,
  isDisabled,
  isSearchable,
  labelClass,
  labelWeightNormal,
  onClick,
  deleteButton,
  buttonClass,
  buttonText,
  loadingRender
}) {
  return (
    <div className={classnames('form-group', { 'has-error has-danger': error })}>
      <label className={labelWeightNormal ? `col-form-label fs-15 pl-12 ${labelClass}` : `col-form-label font-weight-bold fs-15 pl-12 ${labelClass}`}>{label}</label>
      { deleteButton ? 
        <button onClick={onClick} className={`d-block btn button-transparent border-transparent ${buttonClass}`}>
          { buttonText ? 
            <i className="ion-trash-b text-danger cursor-pointer"></i>
            : <i className='ion-close-round cursor-pointer'></i>
          }
          { buttonText ? 
            <span className='mb-0 ml-1 text-danger fs-14'>{buttonText}</span>
          : null }
        </button>
        : loadingRender ? 
          <div>
            <div 
              className="loading-form-wrapper form-control px-20 py-0"
              style={{ borderRadius: '25px', height: '42px!important' }}
            >
              <div className='running-background'></div>
            </div>
          </div>
        : <Select
          styles={{
            // ...styles,
            option: (base, state) => ({
              ...base,
              fontSize: '14px',
              color: state.isFocused
                ? '#000000'
                : state.isSelected
                ? '#ffffff'
                : state.isFocused && state.isSelected
                ? '#ffffff'
                : base.color,
              backgroundColor: state.isSelected
                ? '#f37a4f'
                : base.color,
              '&:hover': {
                backgroundColor: state.isSelected ? "#f37a4f" : '#ffe5db75',
                color: state.isSelected ? "#ffffff" : '#000000'
              },
              transition: '0.2s all ease-in-out',
            }),
            control: (base, state) => ({
              ...base,
              borderRadius: 25,
              paddingLeft: 12,
              paddingRight: 12,
              minHeight: 42,
              fontSize: 12,
              borderColor: error ? 'red' : "#e7e7e7",
              backgroundColor: '#ffffff'
            }),
            indicatorSeparator: (base, state) => ({
              ...base,
              backgroundColor: "transparent!important"
					  })
          }}
          isDisabled={isDisabled}
          value={value}
          onChange={onChange}
          options={options}
          isClearable={isClearable}
          isSearchable={isSearchable}
          placeholder="Select..."
        />
      }
      {error && <div className="form-control-feedback">{error}</div>}
    </div>
  )
}
