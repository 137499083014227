import React, { Component } from "react";
import { detect } from "detect-browser";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";

import Orders from "../orders";
import Notification from "../../../common/Notification";
import DownloadErrorModal from "../DownloadErrorModal";
import DisablePopupBlockerModal from "../DisablePopupBlockerModal";

/*Import redux*/
import { connect } from "react-redux";
import { userGetVoucherOrderList } from "../../../../actions/ordersActions";
import { userDownloadVoucherReceipt } from "../../../../actions/commonActions";
import { SERVER_DOMAIN } from "../../../../utils/apiConnect";

const browser = detect();
class VoucherOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: -1,
      isLoading: false,
      selected: {},
      totalOrders: 0,
      sync: false,
      notification: {},
      errors: {},
      isDownloadError: false,
      isOpenDisablePopupBlockerModal: false
    };
    this.table = React.createRef();
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.modal.sync) {
      if (nextProps.modal.sync !== this.props.modal.sync) {
        this.getOrderList(this.table.current.state);
      }
      return true;
    }
    return true;
  }

  // componentWillUnmount() {
  //   this.props.setSalesTableFilter(this.state.table_filter);
  // }

  getOrderList = (state, instance) => {
    this.setState({ isLoading: true, table_filter: state.filtered, notification: {} });
    // const fromDate = moment(this.state.from_date).subtract("days", 1);
    // const toDate = moment(this.state.to_date).add("days", 1);
    this.props.userGetVoucherOrderList({
      pagination: {
        pageSize: state.pageSize,
        page: state.page,
      },
      sorted: state.sorted,
      filtered: state.filtered,
    }).then((response) => {
      if (response.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "Some features has been updated, please logout and login again",
            isError: true,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          data: response.message.data,
          loading: false,
          pages: response.message.totalPage,
          totalOrders: response.message.totalOrders,
        });
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
    });
  };

  downloadVoucherReceiptHandler = (order_id) => {
    this.setState({ isLoading : true });
    this.props.userDownloadVoucherReceipt({ order_id }).then(res => {
      if(res.status !== 200) {
        this.setState({ isDownloadError: true, isLoading: false });
      } else {
        if(browser.name === 'safari') {
          const { receipt_url } = res.message;
          const directing = window.open(receipt_url, '_blank');
          
          if(!directing) { // means window popup blocker is enabled
            this.setState({
              isOpenDisablePopupBlockerModal: true,
              isLoading: false
            });
          }
        } else {
          // redirect to receipt url
          const { receipt_url } = res.message;
          window.open(receipt_url, '_blank');
          this.setState({ isLoading: false })
        }
      };
    }).catch(err => {
      console.log(err);
      this.setState({ isLoading: false, isDownloadError: true });
    });
  };

  render() {
		const { match } = this.props;
		const {
      data,
      pages,
      isLoading,
      notification,
      totalOrders,
      isDownloadError,
      isOpenDisablePopupBlockerModal
    } = this.state;

    return (
      <div>
        <Orders tab_id="gift-card" match={match} />
				<div className='px-0'>
          <div className="table-card-body mb-30">
            <div className="main-view">
              <p className="text-right fs-16 fw-500 mr-10 py-10">Total : {totalOrders} orders</p>
              <Notification notification={notification} />
              <ReactTable
                filterable
                defaultFiltered={
                  this.props.salesTableFilter ? this.props.salesTableFilter : []
                }
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    Header: "Order ID",
                    accessor: "order_id",
                    sortable: false,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["order_id"] }),
                    filterAll: true,
                    style: { textAlign: "center" },
                  },
                  {
                    Header: "From",
                    accessor: "sender_id.email",
                    sortable: false,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["email"] }),
                    filterAll: true,
                    style: { textAlign: "center" },
                  },
                  {
                    Header: "To",
                    accessor: "receiver_id.email",
                    sortable: false,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["email"] }),
                    filterAll: true,
                    style: { textAlign: "center" },
                  },
                  {
                    Header: "Order date ( MM/DD/YYYY )",
                    accessor: "created_date",
                    sortable: false,
                    filterAll: true,
                    filterable: true,
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["created_date"] }),
                    Cell: (row) => {
                      return <span>{row.value}</span>;
                    },
                    style: { 
                      textAlign: "center",
                      flex: "264.562 0 auto!important",
                      width: "264.562px!important",
                      maxWidth: "264.562px!important",
                    },
                  },
                  {
                    Header: "Payment status",
                    accessor: "payment_status",
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%", height: "100%" }}
                        value={filter ? filter.value : ""}
                      >
                        <option value="">All</option>
                        <option value="open">Open</option>
                        <option value="paid">Paid</option>
                        <option value="canceled">Canceled</option>
                        <option value="expired">Expired</option>
                      </select>
                    ),
                    Cell: (row) => (
                      <span 
                        className='text-capitalized fw-500'
                        style={{
                          color: row.value === "open"
                          ? "#d9bc00"
                          : row.value === "paid"
                          ? "#15db1f"
                          : row.value === "canceled"
                          ? "red"
                          : "gray"
                        }}
                      >
                        { row.value === "open"
                          ? "Open"
                          : row.value === "paid"
                          ? "Paid"
                          : row.value === "canceled"
                          ? "Canceled"
                          : "Expired" }
                      </span>
                    ),
                    style: { textAlign: "center" },
                  },
                  {
                    Header: "Voucher",
                    accessor: "description",
                    sortable: false,
                    filterAll: true,
                    filterable: true,
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["description"] }),
                    Cell: (row) => {
                      return <span className='text-capitalize'>{row.value}</span>;
                    },
                    style: { 
                      textAlign: "center",
                      flex: "264.562 0 auto!important",
                      width: "264.562px!important",
                      maxWidth: "264.562px!important",
                    },
                  },
                  {
                    Header: "Amount",
                    accessor: "amount.value",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <span>
                        <span className="ml-2">
                          &euro; { row.value }
                        </span>
                      </span>
                    ),
                    style: { textAlign: "center" },
                  },
                  {
                    Header: "Receipt",
                    accessor: "order_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      const { payment_status } = row.original;
                      return (
                        <div 
                          style={{
                            height: "100%"
                          }}
                          className='d-flex align-items-center justify-content-center'
                        >
                          { payment_status === 'paid' ? 
                            <a
                              className="d-flex align-items-center justify-content-center flex-row btn-round btn-primary border-0 cursor-pointer px-3"
                              style={{height: '100%'}}
                              href={`${SERVER_DOMAIN}/voucher-receipts/RECEIPT_${row.value}.pdf`}
                              
                              target="_blank"
                            >
                              <i className='fa fa-download mr-1 fs-11'></i>
                              download
                            </a>
                          : null }
                        </div>
                      )
                    },
                    style: { textAlign: "center" },
                  },
                  {
                    Header: "",
                    accessor: "payment_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      return (
                      <span>
                        <Link
                          to={{
                            pathname: `${match.path}/details`,
                            state: {
                              payment_id: row.value,
                              order_id: row.original.order_id
                            },
                          }}
                        >
                          <button className="btn-round btn-main-orange border-0 cursor-pointer px-3 py-1">
                            details
                          </button>
                        </Link>
                      </span>
                    )},
                    style: { textAlign: "center" },
                  },
                ]}
                ref={this.table}
                data={data} // should default to []
                pages={pages} // should default to -1 (which means we don't know how many pages we have)
                loading={isLoading}
                manual // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={this.getOrderList}
                defaultPageSize={25}
                showPagination={true}
                className="-striped -highlight"
              />
              { isDownloadError ? 
                <DownloadErrorModal closeModal={() => this.setState({ isDownloadError: false })} />
              : null }
              { isOpenDisablePopupBlockerModal ? 
                <DisablePopupBlockerModal closeModal={() => this.setState({ isOpenDisablePopupBlockerModal: false })} />
              : null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    modal: state.modal
  };
}

export default connect(mapStateToProps, { userGetVoucherOrderList, userDownloadVoucherReceipt })(VoucherOrders);