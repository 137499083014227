import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../privateRoute';
import Layout from '../layout/Layout';
import StudentDetails from './views/students/studentDetails';
import StudentsTable from './views/students/studentsTable';

import TutorDetails from './views/tutors/tutorDetails';
import TutorHistoryAppointment from './views/tutors/tutorHistoryAppointment';
import TutorsTable from './views/tutors/tutorsTable';
import Users from './views/users';

class UsersPage extends React.Component {
	render() {
		const { history, match } = this.props;
		return(
			<Layout history={history}>
                <main className="main-container">
                	<div className="main-content">
                        <Switch>
							<PrivateRoute path={`${match.path}/students/details`} component={StudentDetails}/> 
							<PrivateRoute path={`${match.path}/students`} component={StudentsTable}/> 
							<PrivateRoute path={`${match.path}/tutors/history`} component={TutorHistoryAppointment}/> 
							<PrivateRoute path={`${match.path}/tutors/details`} component={TutorDetails}/> 
							<PrivateRoute path={`${match.path}/tutors`} component={TutorsTable}/> 
							<PrivateRoute path={`${match.path}`} component={Users}/> 
						</Switch>
						{/* <Users match={match} history={history} /> */}
                	</div>
                </main>
             </Layout>
		);
	}
}

export default UsersPage;
