import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../privateRoute";

import Layout from "../layout/Layout";
import Appointment from "./views/appointment";
import AppointmentDetails from "./views/appointmentDetails";

class AppointmentPage extends React.Component {
  render() {
    const { match, history } = this.props;
    return (
      <Layout history={history}>
        <main className="main-container">
          <div className="main-content px-0">
            <Switch>
              <PrivateRoute path={`${match.path}/details`} component={AppointmentDetails} />
              <PrivateRoute path={`${match.path}`} component={Appointment}/>
            </Switch>
          </div>
        </main>
      </Layout>
    );
  }
}

export default AppointmentPage;
