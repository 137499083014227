import React, { Component } from "react";
import { isEmpty } from "lodash";
import MDSpinner from "react-md-spinner";

import Notification from "../../../common/Notification";
import SelectFieldGroup from "../../../common/SelectFieldGroup";
import AsyncSelectFieldGroup from "../../../common/AsyncSelectFieldGroup";
import TextFieldGroupLogin from "../../../common/TextFieldGroupLogin";

import { connect } from 'react-redux';
import { validateStudentAccount } from '../../../common/validations/studentEdit';
import { userGetAllCountries } from '../../../../actions/commonActions';
import { userEditStudent } from '../../../../actions/studentsActions';

class StudentEditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      errors: {},
      student_id: this.props.student_id,
      country: this.props.country,
      city: this.props.city,
      city_options: [],
      street_name: this.props.street_name, 
      house_number: this.props.house_number, 
      postcode : this.props.postcode,
    };
  };

  componentWillMount = () => {
    this.setState({ isLoading: true, notification: {}, errors: {} });

    if(this.props.country && !isEmpty(this.props.country)) {
      const queryInputCountry = this.props.country.label.toLowerCase();
      this.props.userGetAllCountries({query:queryInputCountry})
      .then((response) => {
        let city_options = response.message.data[0].city_options;
        this.setState({ 
          isLoading: false,
          city_options,
        });
      }).catch((error) => {
        console.log(error);
        this.setState({isLoading:false });
      });
    } else {
      this.setState({ isLoading: false });
    };
  };
  
  loadOptionsCountry = (inputValue, callback) => {
    this.props.userGetAllCountries({query:inputValue})
    .then((response) => {
        callback(response.message.data);
    }).catch((error) => {
       this.setState({isLoading:false });
    });
  };

  onChangeSelectCountry = (val) => {
    const country = {
      value: val.value,
      label: val.label
    };

    const city_options = val.city_options;

    this.setState({ country, city_options, city: null });
  };

  onChangeSelect = (e) => {
    this.setState({ city: e.target.value })
  };

  onChangeInput = (e) => {
    this.setState({ [e.target.name] : e.target.value })
  };

  isValid = () => {
    const { errors, isValid } = validateStudentAccount(this.state);
    if(!isValid){
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmitEditAccount = (e) => {
    e.preventDefault();
    const { 
      student_id,
      country, 
      city, 
      street_name, 
      house_number, 
      postcode,
    } = this.state;

    this.setState({ isLoading: true, notification: {}, errors: {} });

    const dataToSubmit = {
      _id: student_id,
      data: {
        country, 
        city, 
        street_name, 
        house_number, 
        postcode,
      }
    };
    // if(this.isValid()) {
      this.props.userEditStudent(dataToSubmit).then(res => {
        if(res.status === 200) {
          this.setState({ 
            isLoading: false,
          });
          this.props.closeEditing();
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true
            }
          });
        };
      });
    // } else {
    //   this.setState({
    //     isLoading: false,
    //     notification: {
    //       text: 'Please complete the form above',
    //       isError: true
    //     }
    //   });
    // };
  };

  render() {
    const {
      country, 
      city, 
      city_options,
      street_name, 
      house_number, 
      postcode,
      isLoading,
      notification,
      errors
    } = this.state;

    return (
      <div>
        <h4 className="text-center">Edit account</h4>
        <hr className='my-20'/>
        <div className="row m-auto">
          <div className="col-md-6">
            <AsyncSelectFieldGroup
              placeholder="Type to search"
              label="Country"
              value={country}
              loadOptions={this.loadOptionsCountry}
              onChange={this.onChangeSelectCountry}
              error={errors.country}
              isDisabled={isLoading}
              isClearable={false}
            />
          </div>
          <div className="col-md-6">
            <SelectFieldGroup
              label="City"
              value={city}
              options={city_options}
              onChange={(val) => this.onChangeSelect({ target: { name: "city", value: val } })}
              error={errors.city}
              isClearable={false}
              isDisabled={isLoading || isEmpty(country)}
              isSearchable={true}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Street Name"
              field="street_name"
              value={street_name}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.street_name}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="House Number"
              field="house_number"
              value={house_number}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.house_number}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Postal code"
              field="postcode"
              value={postcode}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.postcode}
            />
          </div>
          <div className="col-md-12 my-10">
            <Notification notification={notification} />
          </div>
        </div>
        <hr className='my-20' />
        { isLoading ? 
          <div className='d-flex align-items-center justify-content-end'>
            <MDSpinner />
          </div>
          : <div className='d-flex align-items-center justify-content-end'>
            <button onClick={this.onSubmitEditAccount} className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>Save changes</button>
            <button onClick={() => this.props.closeEditing()} className='btn border-orange btn-round letter-spacing-0'>Cancel</button>
          </div>
        }
      </div>
    );
  }
}

export default connect(null, { userGetAllCountries, userEditStudent })(StudentEditAccount);
