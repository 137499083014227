import { API } from '../utils/apiConnect';

export function userGetVoucherList(data) {
	return dispatch => {
		return API.post('/gift-voucher-crm/list', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetAllVouchers() {
	return dispatch => {
		return API.post('/gift-voucher-crm/all')
		.then( res => {
			return res.data;
		});
	}
}

export function userGetSingleVoucher(data) {
	return dispatch => {
		return API.post('/gift-voucher-crm/single', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userEditVoucher(data) {
	return dispatch => {
		return API.post('/gift-voucher-crm/edit', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userAddVoucher(data) {
	return dispatch => {
		return API.post('/gift-voucher-crm/add', data)
		.then( res => {
			return res.data;
		});
	}
}