import isEmpty from "lodash/isEmpty";
import Validator from "validator";
// import moment from 'moment';

export function validateTutorProfile(data) {
  let errors = {}

  if(isEmpty(data.first_name)) {
    errors.first_name = 'First name is required'
  }
  if(isEmpty(data.last_name)) {
    errors.last_name = 'Last name is required'
  }
  if(isEmpty(data.email)) {
    errors.email = 'Email is required'
  } else {
    if (!Validator.isEmail(data.email)) {
      errors.email = 'Email is not valid';
    };
  };

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export function validateTutorAccount(data) {
  let errors = {}

  if(isEmpty(data.country)) {
    errors.country = 'Please select country you lives in'
  } else {
    if(isEmpty(data.city)) {
      errors.city = 'Please select country you lives in'
    }
  }
  
  if(isEmpty(data.street_name)) {
    errors.street_name = 'Street name is required'
  }
  if(isEmpty(data.house_number)) {
    errors.house_number = 'House number is required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
};

export function validateTutorEmergencyContact(data) {
  let errors = {}

  if(isEmpty(data.email_ec)) {
    errors.email_ec = 'Email is required'
  } else {
    if(!Validator.isEmail(data.email_ec)){
      errors.email_ec = "Please insert a valid email";
    }
  }

  if(isEmpty(data.full_name_ec)) {
    errors.full_name_ec = 'Fullname is required'
  }
  
  if(isEmpty(data.street_name_ec)) {
    errors.street_name_ec = 'Street name is required'
  }

  if(isEmpty(data.house_number_ec)) {
    errors.house_number_ec = 'House number is required'
  }

  if(isEmpty(data.postcode_ec)) {
    errors.postcode_ec = 'Postal code is required'
  }

  if(isEmpty(data.phone_ec)) {
    errors.phone_ec = 'Phone number is required'
  } else {
    if(isNaN(data.phone_ec)) {
      errors.phone_ec = 'Phone number must be a number'
    }
  }

  if(isEmpty(data.country_ec)) {
    errors.country_ec = 'Please select country you lives in'
  } else {
    if(isEmpty(data.city_ec)) {
      errors.city_ec = 'Please select country you lives in'
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
};

