import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from 'lodash';

import LoadingGift from '../../../common/LoadingGift';
import Notification from '../../../common/Notification';
import TextFieldDetailsGroup from '../../../common/TextFieldDetailsGroup';

import { connect } from 'react-redux';
import { userCheckVoucherOrderDetails, userCancelVoucherOrder } from '../../../../actions/ordersActions';

class VoucherOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoading_cancel: false,
      notification: {},
      errors: {},
      order_id: this.props.history.location.state.order_id,
      payment_id: this.props.history.location.state.payment_id,
      payment_method: '',
      payment_status: '',
      description: '',
      amount: '',
      settlementAmount: null,
      created_date: '',
      paid_date: '',
      sender_id: null,
      receiver_id: null,
    };
  };

  componentWillMount = () => {
    this.getSingleOrderDetails();
  };

  goBack = () => {
    this.props.history.goBack()
  };

  getSingleOrderDetails = () => {
    this.setState({ isLoading: true, notiication: {}, errors: {} });
    this.props.userCheckVoucherOrderDetails({ payment_id: this.state.payment_id }).then(res => {
      if(res.status !== 200) {
        this.setState({ 
          isLoading: false,
          notification: {
            text: 'Internal server error. Please try again later.',
            isError: true,
          }
        });
      } else {
        this.setState({ 
          isLoading: false,
          payment_method: res.message.data.payment_method,
          payment_status: res.message.data.payment_status,
          description: res.message.data.description,
          amount: res.message.data.amount,
          settlementAmount: res.message.data.settlementAmount,
          paid_date: res.message.data.paid_date,
          created_date: res.message.data.created_date,
          sender_id: res.message.data.sender_id,
          receiver_id: res.message.data.receiver_id
        })
      }
    }).catch(err => {
      console.log(err);
      this.setState({
        isLoading: false,
        notification: {
          text: "Some features has been updated, please logout and login again",
          isError: true
        }
      });
    });
  };

  onCancelOrder = () => {
    this.setState({ isLoading_cancel: true, notification: {} });
    this.props.userCancelVoucherOrder({ payment_id: this.state.payment_id }).then(res => {
      if(res.status === 200) {
        this.setState({ isLoading_cancel: false });
        this.getSingleOrderDetails();
      } else {
        this.setState({ 
          isLoading_cancel: false,
          notification: {
            text: 'Internal server error. Please try again later.',
            isError: true,
          }
        });
      };
    }).catch(err => {
      console.log(err);
      this.setState({
        isLoading_cancel: false,
        notification: {
          text: "Some features has been updated, please logout and login again",
          isError: true
        }
      });
    });
  };

  render() {
    const {
      isLoading,
      notification,
      order_id,
      payment_id,
      payment_method,
      payment_status,
      description,
      amount,
      settlementAmount,
      created_date,
      paid_date,
      sender_id,
      receiver_id,
    } = this.state;

    const { history } = this.props;

    if(!isEmpty(history.location.state)) {
      return (
        <div>
          <div className='card mx-15'>
            <div className='card-header flex-column justify-content-center'>
              <div role='button' onClick={this.goBack} id='muted-text-group' className='position-absolute d-flex align-items-center cursor-pointer' style={{left: 34}}>
                <i className='ion-chevron-left cursor-pointer fs-30 mr-20'></i>
              </div>
              <h1 className='mb-0'>
                Gift card order details
              </h1>
              <div 
                id='muted-text-group' 
                style={{right: 34}}
                className='position-absolute d-flex align-items-center justify-content-center cursor-pointer font-weight-bold btn-transparent border-0 py-10 px-20' 
              >
                <h4 className='mb-0 mr-2'>Order ID :</h4>
                <h4 className='mb-0'>{order_id}</h4>
              </div>
            </div>
          </div>
          { isLoading ? 
            <div className='d-flex flex-column align-items-center justify-content-center' style={{minHeight: 400}}>
              <LoadingGift />
              <p className='mb-0 fs-14 text-center letter-spacing-0'>Collecting the order details data</p>
            </div>
            : <div>
              <div className='row mx-auto'>
                <div className='col-md-12'>
                  <Notification notification={notification} /> 
                </div>
                {/* SENDER DETAILS */}
                <div className='col-md-6'>
                  <div className='card card-body py-20 px-30'>
                    <h5 className='text-center mb-0'>Sender Details</h5>
                    <hr className='my-20'/>
                    <div className='row'>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          addStyle={{textTransform: 'capitalize'}}
                          label='First Name' 
                          value={sender_id ? sender_id.first_name : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          addStyle={{textTransform: 'capitalize'}}
                          label='Last Name' 
                          value={sender_id ? sender_id.last_name : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Email'
                          value={sender_id ? sender_id.email : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Phone'
                          value={sender_id ? sender_id.phone : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup
                          addStyle={{textTransform: 'capitalize'}} 
                          label='From Country'
                          value={sender_id && sender_id.from_country ? sender_id.from_country.label : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup
                          addStyle={{textTransform: 'capitalize'}} 
                          label='From City'
                          value={sender_id && sender_id.from_city ? sender_id.from_city.label : '-'} 
                        />
                      </div>
                    </div>
                  </div>
                  { payment_status && payment_status === 'open' ? 
                    <div className='text-center'>
                      <button onClick={this.onCancelOrder} className='btn btn-main-orange btn-round'>Cancel Order</button>
                    </div>
                  : null }
                </div>

                {/* RECIPIENT DETAILS */}
                <div className='col-md-6'>
                  <div className='card card-body py-20 px-30'>
                    <h5 className='text-center mb-0'>Recipient Details</h5>
                    <hr className='my-20'/>
                    <div className='row'>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          addStyle={{textTransform: 'capitalize'}}
                          label='First Name' 
                          value={receiver_id ? receiver_id.first_name : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          addStyle={{textTransform: 'capitalize'}}
                          label='Last Name' 
                          value={receiver_id ? receiver_id.last_name : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Email'
                          value={receiver_id ? receiver_id.email : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Phone'
                          value={receiver_id ? receiver_id.phone : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          addStyle={{textTransform: 'capitalize'}}
                          label='From Country'
                          value={receiver_id && receiver_id.from_country ? receiver_id.from_country.label : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          addStyle={{textTransform: 'capitalize'}}
                          label='From City'
                          value={receiver_id && receiver_id.from_city ? receiver_id.from_city.label : '-'} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mx-auto'>
                <div className='col-md-6'>
                  <div className='card card-body py-20 px-30'>
                    <h5 className='text-center mb-0'>Payment Details</h5>
                    <hr className='my-20'/>
                    <div className='row'>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Payment ID' 
                          value={payment_id ? payment_id : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Payment Status'
                          addStyle={{
                            textTransform: 'Capitalize',
                            color: payment_status && payment_status === "open"
                            ? "#d9bc00"
                            : payment_status && payment_status === "paid"
                            ? "#15db1f"
                            : payment_status && payment_status === "canceled"
                            ? "red"
                            : "gray" 
                          }} 
                          value={payment_status ? payment_status : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Payment Method'
                          addStyle={{
                            textTransform: 'Capitalize',
                          }} 
                          value={payment_method ? payment_method : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Order Date' 
                          addStyle={{
                            textTransform: 'Capitalize'
                          }} 
                          value={created_date ? moment(created_date, 'MM/DD/YYYY, HH:mm:ss').format('MMM DD, YYYY - HH:mm:ss') : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Voucher'
                          addStyle={{
                            textTransform: 'Capitalize',
                          }} 
                          value={description ? description : '-'} 
                        />
                      </div>
                      <div className='col-md-6'>
                        <TextFieldDetailsGroup 
                          label='Amount'
                          currency='&euro;'
                          addStyle={{
                            textTransform: 'Capitalize',
                          }} 
                          value={amount ? `${amount.value}` : '-'} 
                        />
                      </div>
                      { payment_status === 'paid' ? 
                        <div className='col-md-6'>
                          <TextFieldDetailsGroup 
                            label='Paid Date' 
                            addStyle={{
                              textTransform: 'Capitalize'
                            }} 
                            value={paid_date ? moment(paid_date, 'MM/DD/YYYY, HH:mm:ss').format('MMM DD, YYYY - HH:mm:ss') : '-'} 
                          />
                        </div>
                      : payment_status === 'canceled' ? 
                        <div className='col-md-6'>
                          <TextFieldDetailsGroup 
                            label='Canceled Date' 
                            addStyle={{
                              textTransform: 'Capitalize'
                            }} 
                            value={paid_date ? moment(paid_date, 'MM/DD/YYYY, HH:mm:ss').format('MMM DD, YYYY - HH:mm:ss') : '-'} 
                          />
                        </div>
                      : null }
                      { payment_status === 'paid' ? 
                        <div className='col-md-6'>
                          <TextFieldDetailsGroup 
                            label='Settlement Amount'
                            currency='&euro;'
                            addStyle={{
                              textTransform: 'Capitalize',
                            }} 
                            value={settlementAmount ? `${settlementAmount.value}` : '-'} 
                          />
                        </div>
                      : null }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      );
    } else {
      return <Redirect to='/orders' />;
    }
  }
}

export default connect(null, { userCheckVoucherOrderDetails, userCancelVoucherOrder })(VoucherOrderDetails);
