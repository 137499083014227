import React from 'react'
import classnames from 'classnames'

const TextAreaFieldGroup = ({
  field,
  value,
  id,
  label,
  error,
  onChange,
  disabled,
  icon,
  placeholder,
}) => {
  return (
    <div className={classnames('form-group', { 'has-error has-danger': error })}>
      { label ? 
        <label className="col-form-label font-weight-bold fs-15 pl-12">{label}</label>
      : null }
      <textarea
        id='textarea'
        rows='6'
        value={value}
        onChange={onChange}
        name={field}
        className="form-control px-20 py-2 h-140px"
        style={{ borderRadius: '15px' }}
        disabled={disabled}
        placeholder={placeholder}
      />
      {error && <div className="form-control-feedback">{error}</div>}
      {icon && <div className={icon}></div>}
    </div>
  )
}

export default TextAreaFieldGroup
