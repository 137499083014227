import React from 'react';
import Layout from '../layout/Layout';
import Dashboard from './views/dashboard';

class dashboardPage extends React.Component {
	render() {
		const { history } = this.props;
		return(
			<Layout history={history}>
                <main className="main-container">
                	<div className="main-content">
                        <Dashboard history={history} />
                	</div>
                </main>
             </Layout>
		);
	}
}

export default dashboardPage;
