import React, { Component } from "react";
import moment from "moment";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";

import Notification from '../../../common/Notification';
import DateRangeSelectGroup from '../../../common/DateRangeSelectGroup';

import { connect } from "react-redux";
import { userGetTutorAppointmentHistory } from '../../../../actions/tutorsActions';
class TutorHistoryAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutor_id: this.props.location.state.tutor_id,
      tutor_name: this.props.location.state.tutor_name,
      data: [],
      pages: 1,
      isLoading: false,
      selected: {},
      totalAppointments: 0,
      total_credits: 0,
      start_period: null,
      end_period: null,
      sync: false,
      notification: {},
      errors: {},
    };
    this.table = React.createRef();
  };

  getAppointmentsList = (state, instance) => {
    this.setState({ isLoading: true, table_filter: state.filtered, notification: {} });
    this.props.userGetTutorAppointmentHistory({
      pagination: {
        pageSize: state.pageSize,
        page: state.page,
      },
      sorted: state.sorted,
      filtered: state.filtered,
      tutor_id: this.state.tutor_id,
    }).then((res) => {
      if (res.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "Some features has been updated, please logout and login again",
            isError: true,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          data: res.message.data,
          loading: false,
          pages: res.message.totalPage,
          totalAppointments: res.message.totalAppointments,
          total_credits: res.message.totalCredits
        });
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
    });
  };

  onChangeDateSelect = (date, period) => {
    this.setState({ 
      [period]: date,
    });
  };

  onFilterDateRange = () => {
    this.setState({ isLoading: true, notification: {}});
    const startDate = moment(this.state.start_period);
    const endDate = moment(this.state.end_period);
    this.props.userGetTutorAppointmentHistory({
      pagination: {
        pageSize: 25,
        page: 0
      },
      sorted: [],
      filtered: [],
      tutor_id: this.state.tutor_id,
      startPeriod: moment(startDate).toISOString(),
      endPeriod: moment(endDate).toISOString()
    }).then((response) => {
      if(response.status !== 200){
        this.setState({
          isLoading:false,
          notification:{
            text: response.message,
            isError: true
          }
        });
      } else {
        this.setState({
          isLoading: false,
          data: response.message.data,
          loading: false,
          pages: response.message.totalPage,
          totalAppointments: response.message.totalAppointments,
          total_credits: response.message.totalCredits
        });
      }
    }).catch((error) => {
      console.log(error)
      this.setState({
        isLoading : false,
        notification: {
          text: "Internal server error. Please try again later",
          isError: true
        }

      })
    });
  };

  onResetFilterDateRange = () => {
    this.setState({
      start_period: null,
      end_period: null,
      isLoading: true,
      notification: {}
    });

    this.props.userGetTutorAppointmentHistory({
      pagination: {
        pageSize: 25,
        page: 0
      },
      sorted: [],
      filtered: [],
      tutor_id: this.state.tutor_id,
    }).then((response) => {
      if(response.status !== 200){
        this.setState({
          isLoading:false,
          notification:{
            text: response.message,
            isError: true
          }
        });
      } else {
        this.setState({
          isLoading: false,
          data: response.message.data,
          loading: false,
          pages: response.message.totalPage,
          totalAppointments: response.message.totalAppointments,
          total_credits: response.message.totalCredits
        });
      }
    }).catch((error) => {
      console.log(error)
      this.setState({
        isLoading : false,
        notification: {
          text: "Internal server error. Please try again later",
          isError: true
        }
      })
    });
  };

  goBack = () => {
    this.props.history.goBack()
  };
  
  render() {
    const {
      data,
      start_period,
      end_period,
      pages,
      isLoading,
      notification,
      totalAppointments,
      tutor_name,
      total_credits
    } = this.state;

    return (
      <div>
        <div className='card'>
          <div className='card-header flex-column justify-content-center'>
            <div role='button' onClick={this.goBack} id='muted-text-group' className='position-absolute d-flex align-items-center cursor-pointer' style={{left: 34}}>
              <i className='ion-chevron-left cursor-pointer fs-30 mr-20'></i>
            </div>
            <h1 className="text-center text-capitalize">{tutor_name}'s Appointments history</h1>
          </div>
        </div>
        <div className="card card-body p-30">
          <div className="main-view">
            <div className='d-flex align-items-center justify-content-between mb-20'>
              <div className='d-flex align-items-center'>
                <p className="mb-0 mr-2">Select period :</p>
                <DateRangeSelectGroup 
                  startDate={start_period}
                  endDate={end_period}
                  onChange={this.onChangeDateSelect}
                  isDisabled={isLoading}
                />
                <button onClick={this.onFilterDateRange} className='btn btn-main-orange btn-round py-2 px-30 mx-2'>Filter</button>
                <button onClick={this.onResetFilterDateRange} className='btn btn-main-transparent btn-round py-2 px-24'>Reset</button>
              </div>
              <div className='d-flex align-items-center'>
                <p className="text-right fs-16 fw-500 mb-0 text-left">
                  Total : {totalAppointments} {totalAppointments > 1 ? 'appointments' : 'appointment'}
                </p>
                <span className='mx-3'>|</span>
                <div className='d-flex align-items-center'>
                  <img 
                    alt="" 
                    className='h-20px' 
                    src='/assets/img/icon/flowently-credit.svg'
                  />
                  <p className="text-right fs-16 fw-500 mb-0 text-left ml-2">
                    {total_credits} {total_credits > 1 ? 'credits' : 'credit'}
                  </p>
                </div>                
              </div>
            </div>
            <Notification notification={notification} />
            <ReactTable
              filterable
              defaultFiltered={
                this.props.salesTableFilter ? this.props.salesTableFilter : []
              }
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "Session ID",
                  accessor: "booking_id",
                  sortable: false,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["booking_id"] }),
                  filterAll: true,
                  style: { textAlign: "center" },
                },
                {
                  Header: "Student first name",
                  accessor: "student_id.first_name",
                  sortable: false,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["first_name"] }),
                  filterAll: true,
                  style: { textAlign: "center" },
                },
                {
                  Header: "Student last name",
                  accessor: "student_id.last_name",
                  sortable: false,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["last_name"] }),
                  filterAll: true,
                  style: { textAlign: "center" },
                },
                {
                  Header: "Session date ( MM/DD/YYYY )",
                  accessor: "appointment_date",
                  sortable: false,
                  filterAll: true,
                  filterable: true,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["appointment_date"],
                    }),
                  Cell: (props) => {
                    return <span>{props.row.appointment_date}</span>;
                  },
                  style: {
                    textAlign: "center",
                    flex: "264.562 0 auto!important",
                    width: "264.562px!important",
                    maxWidth: "264.562px!important",
                  },
                },
                {
                  Header: "Type of session",
                  accessor: "session_type.value",
                  Filter: ({ filter, onChange }) => {
                    return (
                      <select
                        className='form-control'
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%", height: "100%" }}
                        value={filter ? filter.value : ""}
                      >
                        <option value="">All</option>
                        <option value="live">Live</option>
                        <option value="skype">Online</option>
                        <option value="children">Kids</option>
                      </select>
                    )
                  },
                  Cell: (row) => {
                    return (
                      <span>
                        <span className="ml-2">
                          { 
                            row.value === 'live' ? 'Live Session' :  
                            row.value === 'skype' ? 'Online Session' : 
                            'Kids Session'
                          }
                        </span>
                      </span>
                    )
                  },
                  style: { textAlign: "center" },
                },
                {
                  Header: "Order status",
                  accessor: "booking_status",
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%", height: "100%" }}
                      value={filter ? filter.value : ""}
                    >
                      <option value="">All</option>
                      <option value="open">New</option>
                      <option value="confirmed">Confirmed</option>
                      <option value="success">Completed</option>
                      <option value="cancel">Cancelled</option>
                      <option value="expired">Expired</option>
                    </select>
                  ),
                  Cell: (row) => (
                    <span
                      className="text-capitalized fw-500"
                      style={{
                        color:
                          row.value === "open"
                            ? "#0d8fe0"
                            : row.value === "success"
                            ? "#288a42"
                            : row.value === "cancel"
                            ? "red"
                            : row.value === "confirmed"
                            ? '#15db1f'
                            : "gray",
                      }}
                    >
                      {row.value === "open"
                        ? "New"
                        : row.value === "success"
                        ? "Completed"
                        : row.value === "confirmed"
                        ? "Confirmed"
                        : row.value === "cancel"
                        ? "Cancelled"
                        : "Expired"}
                    </span>
                  ),
                  style: { textAlign: "center" },
                },
                // {
                //   Header: "Confirmation status",
                //   accessor: "isConfirmed",
                //   Filter: ({ filter, onChange }) => (
                //     <select
                //       onChange={(event) => onChange(event.target.value)}
                //       style={{ width: "100%", height: "100%" }}
                //       value={filter ? filter.value : ""}
                //     >
                //       <option value="">All</option>
                //       <option value="true">Confirmed</option>
                //       <option value="false">Waiting for confirmation</option>
                //     </select>
                //   ),
                //   Cell: (row) => (
                //     <span className="text-capitalized fw-500">
                //       <span
                //         style={{
                //           color: row.value ? "#15db1f" : "#ff2e00",
                //           transition: "all .3s ease",
                //           marginRight: 10,
                //         }}
                //       >
                //         &#x25cf;
                //       </span>
                //       {row.value ? "Confirmed" : "Waiting"}
                //     </span>
                //   ),
                //   style: { textAlign: "center" },
                // },
                {
                  Header: "Duration",
                  accessor: "session_duration",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    return (
                      <span>
                        <span className="ml-2">{row.value} minutes</span>
                      </span>
                    );
                  },
                  style: { textAlign: "center" },
                },
                {
                  Header: "Credit charged",
                  accessor: "book_price",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => (
                    <span>
                      <span className="ml-2">{row.value} flowently credits</span>
                    </span>
                  ),
                  style: { textAlign: "center" },
                },
              ]}
              ref={this.table}
              data={data} // should default to []
              pages={pages} // should default to -1 (which means we don't know how many pages we have)
              loading={isLoading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={this.getAppointmentsList}
              defaultPageSize={15}
              showPagination={true}
              className="-striped -highlight"
            />
          </div>
        </div>
      </div>
    );
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    modal: state.modal,
  }
};

export default connect(mapStateToProps, { userGetTutorAppointmentHistory })(TutorHistoryAppointment);