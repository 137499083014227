import React from 'react'
import classnames from 'classnames'
import AsyncSelect from 'react-select/async'

class AsyncSelectFieldGroup extends React.Component {
  render() {
    const {
      placeholder,
      label,
      value,
      loadOptions,
      onChange,
      error,
      isDisabled,
      isClearable,
      hideLabel,
      fontSize,
      customHeight
    } = this.props

    return (
      <div className={classnames('form-group', { 'has-error has-danger': error })}>
        { hideLabel ? null 
          : <label className="col-form-label font-weight-bold fs-15 pl-12">{label}</label>
        }
        <AsyncSelect
          styles={{
            // ...styles,
            option: (base, state) => ({
              ...base,
              fontSize: fontSize ? fontSize : '14px',
              color: state.isFocused
                ? '#000000'
                : state.isSelected
                ? '#ffffff'
                : base.color,
              backgroundColor: state.isFocused
                ? '#e8fcfa'
                : state.isSelected
                ? '#33CABB'
                : base.color,
              transition: '0.2s all ease-in-out',
            }),
            control: (base, state) => ({
              ...base,
              borderRadius: 25,
              paddingLeft: 12,
              paddingRight: 12,
              minHeight: customHeight ? customHeight : base.minHeight,
              borderWidth: error ? '1px' : base.borderWidth,
              borderColor: error ? 'red' : '#ebebeb',
            }),
          }}
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
          // components={{ DropdownIndicator:() => null }}
          noOptionsMessage={() => "Type to search"}
          placeholder={placeholder}
          isClearable={isClearable}
        />
        {error && <div className="form-control-feedback">{error}</div>}
      </div>
    )
  }
}

export default AsyncSelectFieldGroup
