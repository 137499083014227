import React from 'react'
import { Link } from 'react-router-dom'

const titleCase = str => {
  const string = str.split('-').join(' ');
  const result = string.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  });
  return  result;
}

const renderPath = (part, partIndex, parts) => {
  const path = ['', ...parts.slice(0, partIndex + 1)].join('/')
  let lastItem = partIndex + 1 === parts.length
  return (
    <li key={path} className="breadcrumb-item text-capitalize cursor-default fs-13">
      {lastItem ? (
        titleCase(part)
      ) : (
        <Link className="text-info text-capitalize cursor-pointer" to={path}>
          {titleCase(part)}
        </Link>
      )}
    </li>
  )
}

class Breadcrumb extends React.Component {
  render() {
    const { history } = this.props;
    let parts = history.location.pathname.split('/')
    // const current = parts[parts.length-1];
    parts = parts.slice(1, 3)
    return (
      <ol className="breadcrumb breadcrumb-arrow mb-0">
        <li key="dashboard" className="breadcrumb-item fs-13">
          <Link className="text-info" to="/dashboard">
            Home
          </Link>
        </li>
        {parts.map(renderPath)}
      </ol>
    )
  }
}

export default Breadcrumb
