import { SET_USERS_TAB_ACTIVE } from '../actions/types';

const initialState = {
    users: '',
}

export default (state = initialState, action = {}) => {
	switch(action.type) {
		case SET_USERS_TAB_ACTIVE:
			return {
                ...state,
				users: action.tab_id
			}			
		default: return state;
	}	
}