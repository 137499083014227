import React from 'react'
import { SERVER_DOMAIN } from '../../../../utils/apiConnect';
import TextFieldDetailsGroup from '../../../common/TextFieldDetailsGroup';

const StudentPackageOrderDetailsModal = ({ onCloseModal, data }) => {
  let package_name = '';

  if(data && data.description) {
    package_name = data.description.charAt(0).toUpperCase()+data.description.substr(1).toLowerCase()
  };
  
  let payment_status_background = '';
  
  if(data && data.payment_status) {
    payment_status_background = data.payment_status === 'paid' || data.payment_status === 'authorized' ? 'status-box-success' 
    : data.payment_status === 'failed' ? 'status-box-failed' 
    : data.payment_status === 'pending' ? 'status-box-pending' 
    : data.payment_status === 'open' ? 'status-box-open' 
    : data.payment_status === 'canceled' ? 'status-box-canceled'
    : 'status-box-gray'
  };

  return (
    <div id="student-transaction-details-modal">
			<div className="modal modal-center fade show" id="modal-center" style={{display: 'block', paddingRight: 15}}>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header px-30">
							<h5 className="modal-title">Transaction Details</h5>
							<button onClick={onCloseModal} type="button" className="close cursor-pointer">
								<span className='cursor-pointer' aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body" style={{ overflowY: "auto!important" }}>
              <div className='d-flex align-items-center justify-content-between px-15'>
                <h3 className='mb-0'>Order ID : { data && data.order_id ? data.order_id : '-' }</h3>
                <div className={`${payment_status_background} mb-0`}>{ data && data.payment_status ? data.payment_status : '-' }</div>
              </div>
              <hr className='mt-20 mb-30' />
              <div className='row mx-auto'>
                <div className='col-md-6'>
                  <TextFieldDetailsGroup 
                    label='Package name' 
                    value={data && data.description ? package_name : '-'} 
                  />
                </div>
                <div className='col-md-6'>
                  <TextFieldDetailsGroup 
                    label='Amount' 
                    currency='&euro;'
                    value={data && data.amount ? data.amount.value : '-'} 
                  />
                </div>
                <div className='col-md-6'>
                  <label style={{ color: "#959595" }} className="mb-0 fs-14 fw-500">
                    Receipt
                  </label>
                  <div className='mt-2 mb-20'>
                    <a
                      className="btn-primary border-0 cursor-pointer px-3 py-2"
                      style={{borderRadius: '8px'}}
                      href={`${SERVER_DOMAIN}/receipts/RECEIPT_${data.order_id}.pdf`}
                      
                      target="_blank"
                    >
                      <i className='fa fa-download mr-1 fs-11'></i>
                      download
                    </a>
                  </div>
                </div>
              </div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal-backdrop fade show" style={{opacity: '0.8!important'}}></div>
		</div>
  );
};

export default StudentPackageOrderDetailsModal;