import React, { Component } from "react";
import TextFieldDetailsGroup from "../../../common/TextFieldDetailsGroup";

class StudentAccountDetails extends Component {
  render() {
    const {
      living_in_country,
      living_in_city,
      street_name,
      house_number,
      postcode,
    } = this.props;

    return (
      <div className="row mx-10">
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Living in country"
            value={living_in_country ? living_in_country.label : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Living in city"
            value={living_in_city ? living_in_city.label : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Street name"
            value={street_name ? street_name : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="House number"
            value={house_number ? house_number : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Postal code"
            value={postcode ? postcode : "-"}
          />
        </div>
      </div>
    );
  }
}

export default StudentAccountDetails;
