import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';

import Notification from '../../common/Notification';
import TextAreaGroup from '../../common/TextAreaGroup';
import TextFieldGroup from "../../common/TextFieldGroup";
import ReactSelectFieldGroup from '../../common/ReactSelectFieldGroup';

import { connect } from 'react-redux';
import { validateEditVoucher } from '../../common/validations/voucherEdit';
import { userAddVoucher } from '../../../actions/voucherActions';
import BenefitListInput from './benefitListInput';


class AddVoucherModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      errors: {
        benefit_list: []
      },
      title: "",
      description: "",
      credit_value: "",
      is_active: true,
      status: {value: true, label: 'Active'},
      promo_voucher: false,
      hidden_voucher: {value: false, label: 'Show'},
      origin_price: "",
      sale_price: "",
      benefit_list: [{
        text: '',
        bold_text: false,
        error: ''
      }],
    };
  };

  handleAddBenefit = () => {
    this.setState({
      benefit_list: this.state.benefit_list.concat([
        {
          text: "",
          bold_text: false,
          error: ''
        }
      ])
    });
  };

  onChangeBenefitList = (e, idx) => {
    const new_benefit_list = this.state.benefit_list.map((item, sidx) => {
      if (parseInt(idx, 10) !== sidx) {
        return item;
      } else {
        return { 
          ...item,
          text: e.target.value,
        };
      }
    });
    this.setState({ benefit_list: new_benefit_list });
  };
  
  onChangeInput = e => {
    this.setState({ 
      [e.target.name] : e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name] : null
      }
    });
  };

  onChangeSelect = val => {
    this.setState({
      status: val,
      is_active: val.value
    });
  };

  onChangeShowAtWebsite = val => {
    this.setState({
      hidden_voucher: val,
      promo_voucher: val.value
    });
  };

  isValid = () => {
    const { errors, isValid } = validateEditVoucher(this.state);
    if(!isValid){
      console.log(errors)
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmitAddVoucher = e => {
    e.preventDefault();
    this.setState({ isLoading: true, notification: {}, errors: {}});
    
    const benefit_list = this.state.benefit_list.map((item,idx) => {
      return {
        text: item.text,
        bold_text: idx === 0 ? true : false,
      };
    });
    
    const dataToSubmit = {
      title: this.state.title,
      description: this.state.description,
      credit_value: parseFloat(this.state.credit_value, 100),
      is_active: this.state.is_active,
      promo_voucher: this.state.promo_voucher,
      origin_price: parseFloat(this.state.origin_price, 100),
      sale_price: parseFloat(this.state.sale_price, 100),
      benefit_list,
    };
    
    if(this.isValid()) {
      this.props.userAddVoucher(dataToSubmit).then(res => {
        if(res.status !== 200) {
          this.setState({ 
            isLoading: false,
            notification: {
              isError: true,
              text: res.message,
            }
          });
        } else {
          this.setState({ isLoading: false })
          this.props.onCloseModal();
        }
      });
    } else {
      this.setState({
        isLoading: false,
        notification: {
          isError: true,
          text: "Please complete the form above"
        }
      })
    }
  };

  render() {
    const { 
      title,
      description,
      credit_value,
      status,
      hidden_voucher,
      origin_price,
      sale_price,
      benefit_list,
      isLoading,
      errors,
      notification,
    } = this.state;

    const status_options = [
      { value: true, label: 'Active' },
      { value: false, label: 'Non-active'}
    ];

    const hidden_voucher_options = [
      { value: false, label: 'Show' },
      { value: true, label: 'Hide'},
    ];

    const render_benefits = benefit_list.map((item,idx) => {
        return (
          <div key={`new-benefit-${idx+1}`}>
            <BenefitListInput 
              field="benefit_list"
              label={idx === 0 ? "Benefits" : null}
              value={item.text}
              id={idx}
              isBold={item.bold_text}
              placeholder="Type something"
              onChange={(e) => this.onChangeBenefitList(e, idx)}
              disabled={isLoading}
              error={item.error}
            />
          </div>
        )
      });
  
      let benefits_form = (
        <div>
          { render_benefits }
          { benefit_list.length > 5 ? 
            null
            : <div className='form-group row'>
              <label className="col-form-label col-md-3"></label>
              <div className="col-form-label px-0 col-md-1 d-flex align-items-start justify-content-center">
                <p className='mb-0'> </p>
              </div>
              <div className="col-md-8 pl-0">
                <div className="d-flex align-items-center justify-content-center mb-30">
                  <button type="button" onClick={(e) => this.handleAddBenefit(e)} className="btn btn-transparent border-0 letter-spacing-0 text-info">
                    <i className="ion-plus-circled mr-2"></i>
                    Add new benefit
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      );

    return (
      <div>
        <div
          className="modal modal-center fade show"
          id="modal-center"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ width: "692px"}}>
              <div className="modal-header">
                <h4 className="modal-title text-center font-weight-bold">Add Card</h4>
                <button onClick={this.props.onCloseModal} type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">
                    <i className="ion-close-round fs-16 cursor-pointer"></i>
                  </span>
                </button>
              </div>
              <div className='modal-body voucher-modal m-0 py-30 px-40'>
                <TextFieldGroup
                  field="title"
                  label="Title"
                  value={title}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.title}
                />
                <TextAreaGroup
                  field="description"
                  label="Description"
                  value={description}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.description}
                />
                <TextFieldGroup
                  type="hours"
                  field="credit_value"
                  label="Hours"
                  value={credit_value}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.credit_value}
                />
                <TextFieldGroup
                  type="price"
                  field="sale_price"
                  label="Discount price"
                  value={sale_price}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.sale_price}
                />
                <TextFieldGroup
                  type="price"
                  field="origin_price"
                  label="Origin price"
                  value={origin_price}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.origin_price}
                />
                <ReactSelectFieldGroup
                  label="Status"
                  value={status}
                  field="status"
                  options={status_options}
                  onChange={this.onChangeSelect}
                  isDisabled={isLoading}
                  isSearchable={false}
                  isClearable={false}
                  error={errors.status}
                />
                <ReactSelectFieldGroup
                  label="Show or hide"
                  value={hidden_voucher}
                  field="hidden_voucher"
                  options={hidden_voucher_options}
                  onChange={this.onChangeShowAtWebsite}
                  isDisabled={isLoading}
                  isSearchable={false}
                  isClearable={false}
                  error={errors.hidden_voucher}
                />
                { benefit_list.length === 0 ? 
                  <BenefitListInput 
                    id={0}
                    field="benefit_list"
                    label="Benefits"
                    value={benefit_list[0].text}
                    isBold={benefit_list[0].bold_text}
                    placeholder="Type something"
                    onChange={(e) => this.onChangeBenefitList(e, 0)}
                    disabled={isLoading}
                    error={benefit_list[0].error}
                  />
                : benefits_form }
                <Notification notification={notification} />
              </div>
              <div className='modal-footer p-20'>
                { isLoading ? 
                  <div className='d-flex align-items-center justify-content-end'>
                    <MDSpinner />
                  </div>
                  : <div className='d-flex align-items-center justify-content-end'>
                    <button onClick={this.onSubmitAddVoucher} className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>Add</button>
                    <button onClick={this.props.onCloseModal} className='btn border-orange btn-round letter-spacing-0'>Cancel</button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  };
};

export default connect(null, { userAddVoucher })(AddVoucherModal);
