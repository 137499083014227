import { SHOW_MODAL, HIDE_MODAL } from '../actions/types';

/** Initial State */
const initialModalState = {
  modalType: null,
  id:null,
  sync:false
};

/** Modal reducer */
export default function(state = initialModalState, action) {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case SHOW_MODAL:
            newState.modalType = action.modalType;
            newState.id = action.id;
            newState.sync = false;
            break;
        case HIDE_MODAL:
            newState.modalType = null;
            newState.id = null;
            newState.sync = action.sync;
            break;
        default:
            return state;
    }
    return newState;
}


