import axios from 'axios';

// Set config defaults url
const { REACT_APP_BASE_API, REACT_APP_DOMAIN, REACT_APP_SERVER_DOMAIN } = process.env;

export const API = axios.create({
	baseURL: REACT_APP_BASE_API,
});

export const DOMAIN = REACT_APP_DOMAIN;

export const SERVER_DOMAIN = REACT_APP_SERVER_DOMAIN;
